@use '/src/styles/desktop-variables.scss';

@import '/src/styles/core.scss';

.wrapper {
  @include flex(center, flex-start);
  column-gap: 1rem;
  width: 100%;
  padding: 1.5rem 1rem;
  border-radius: 0.25rem;
  color: desktop-variables.$main-white;

  .icon {
    @include flex();
    @include size(24px, 24px);
    min-width: 24px;
  }

  .preloader {
    animation: 0.9s linear infinite small-rotation;
  }

  @keyframes small-rotation {
    0% {
      transform: rotateZ(0deg);
    }

    100% {
      transform: rotateZ(359deg);
    }
  }
}

.pendingToast {
  background-color: rgba(desktop-variables.$secondary-brand-color, 0.3);
}

.rejectedToast {
  background-color: rgba(desktop-variables.$secondary-red, 0.3);
}

.successToast {
  background-color: rgba(desktop-variables.$secondary-green, 0.2);
}
