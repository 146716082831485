@import '/src/styles/core.scss';

.wrapper {
  @include flex(flex-start, flex-start, column);
  row-gap: 2.5rem;
  width: 100%;

  button,
  a {
    width: 100%;

    button {
      width: 100%;
    }
  }
}
