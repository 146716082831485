@use '/src/styles/desktop-variables.scss';

.wrapper {
  position: relative;
  // display: flex;
  // flex-wrap: wrap;
  display: grid;
  gap: 1.25rem;
  width: 100%;
  justify-content: space-between;
  align-items: flex-start;
  align-content: flex-start;
  height: fit-content;

  .title {
    display: flex;
    column-gap: 1rem;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    padding-top: 0.25rem;
    transform: translateY(-0.25rem);
  }

  .sticky {
    position: sticky;
    top: 0;
    left: 0;
    padding-bottom: 1.25rem;
    margin-bottom: -1.25rem;
    background-color: desktop-variables.$main-background;
    z-index: 3;
  }
}
