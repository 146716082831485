@use '/src/styles/variables.scss';

@import '/src/styles/core.scss';

.dropdownMenu {
  @include flex(flex-start, flex-start, column);
  width: 100%;
  max-height: 100%;
  overflow: hidden;
  overflow-y: auto;
  border-radius: 4px;
  background-color: variables.$main-gray-3;

  /* Custom scrollbar on hover */
  &::-webkit-scrollbar {
    width: 3px;
    display: block;
  }

  /* Background */
  &::-webkit-scrollbar-track {
    background: #202025;
    border-radius: 4px;
    display: block;
    padding: 8px;
  }

  /* Scroll */
  &::-webkit-scrollbar-thumb {
    background: variables.$main-light-gray;
    border-radius: 1.5px;
    display: block;
  }

  .dropdownItem {
    &:not(:last-child) {
      border-bottom: 1px solid variables.$main-dark-gray-1;
    }
  }

  .dropdownItemBorderTop {
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
  }

  .dropdownItemBorderBottom {
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
  }
}
