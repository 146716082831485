@use '/src/styles/variables.scss';
@import '/src/styles/mixins.scss';

.wrapper {
  @include flex(center, center);
  column-gap: 8px;
  width: fit-content;

  .indicator {
    position: relative;
    @include flex();
    width: 16px;
    height: 16px;
    background-color: variables.$main-dark-gray-1;
    border-radius: 0.25rem;

    &:after {
      position: absolute;
      display: block;
      content: '';
      background-color: variables.$secondary-red-1;
      border-radius: 50%;
      transition: all 0.3s;
    }
  }

  .m {
    width: 24px;
    height: 24px;
  }

  .s:after {
    width: 8px;
    height: 8px;
    top: calc(50% - 4px);
    left: calc(50% - 4px);
  }

  .m:after {
    width: 12px;
    height: 12px;
    top: calc(50% - 6px);
    left: calc(50% - 6px);
  }

  .connected:after {
    background-color: variables.$secondary-green-1;
    transition: all 0.3s;
  }

  .controls {
    column-gap: 0.75rem;
    width: fit-content;
  }

  .name {
    font-size: 0.875rem; // 14px
    font-weight: 400;
    line-height: 150%;
  }
}
