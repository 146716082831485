@use '/src/styles/variables.scss';
@import '/src/styles/mixins.scss';

.wrapper {
  all: unset;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px 16px;
  transition: all 0.2s;
  background-color: unset;

  &:focus-within,
  &:focus {
    transition: all 0.2s;
    background-color: variables.$main-dark-gray-2;
  }

  &.button {
    width: calc(100% - 32px);
  }

  cursor: pointer;

  &.bottomBorder {
    border-bottom: 1px solid variables.$main-gray-5;
  }

  .leftBlock {
    display: flex;
    justify-content: center;
    align-items: center;
    column-gap: 12px;

    .title {
      color: variables.$main-white;
      text-transform: unset;
    }

    .icon {
      display: flex;
      width: 44px;
      height: 44px;
      justify-content: center;
      align-items: center;
      border-radius: 100px;
      background-color: variables.$main-dark-gray-1;

      .icon > svg {
        @include size(20px, 20px);
      }
    }
  }

  .rightArrowIcon {
    @include size(24px, 24px);
    opacity: 0.8;
  }
}
