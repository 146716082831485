@import '/src/styles/core.scss';

.wrapper {
  @include flex(flex-start, flex-start, column);
  row-gap: 1.25rem;
  width: 100%;

  button {
    margin-top: 0.75rem;
    width: 100%;
  }
}
