@import '../../../styles/core.scss';

.number {
  font-style: normal;
  font-family: $work-sans;
  font-weight: 400;

  &H1 {
    font-weight: 600;
    font-size: 50px;
    line-height: 61px;
  }

  &H2 {
    font-weight: 600;
    font-size: 44px;
    line-height: 61px;
  }

  &H3 {
    font-weight: 600;
    font-size: 32px;
    line-height: 38px;
  }

  &H4 {
    font-weight: 500;
    font-size: 24px;
    line-height: 29px;
  }

  &H5 {
    font-weight: 500;
    font-size: 21px;
    line-height: 26px;
  }

  &H6 {
    font-weight: 500;
    font-size: 18px;
    line-height: 22px;
  }

  &Body {
    font-size: 14px;
    line-height: 17px;
  }

  &Body1 {
    font-size: 15px;
    line-height: 18px;
  }

  &Body2 {
    font-size: 13px;
    line-height: 18px;
  }

  &Fw500 {
    font-weight: 500;
  }

  &Fw600 {
    font-weight: 600;
  }

  &Fw700 {
    font-weight: 700;
  }

  &Small {
    font-size: 12px;
    line-height: 14px;
  }

  &Lh13 {
    line-height: 13px;
  }

  &Lh18 {
    line-height: 18px;
  }

  &Secondary {
    opacity: 0.8;
  }

  &Inverted {
    color: var(--white);
  }
}
