@use '/src/styles/variables.scss';
@import '/src/styles/core.scss';

.wrapper {
  @include flex(center, flex-start, column);
  width: 100%;
  row-gap: 1px;
  max-height: calc(100vh - 110px - 16px);
  overflow-y: auto;
  padding-bottom: 3.75rem;

  .message {
    width: 100%;

    .labelDate {
      text-transform: uppercase;
      padding: 20px;
      background: variables.$main-dark-gray-1;
    }
  }

  .emptyPage {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    row-gap: 12px;
    margin-top: 128px;
    padding: 0 56px;
    background: variables.$main-dark-gray-2;

    .desc {
      opacity: 0.8;
      text-align: center;
    }

    .header {
      display: flex;
      flex-direction: column;
      align-items: center;
      row-gap: 16px;

      .title {
        opacity: 0.9;
      }
    }
  }
}
