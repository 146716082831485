@use '/src/styles/variables.scss';

@import '/src/styles/core.scss';

.drawer {
  position: fixed;
  z-index: variables.$z-drawer;
  height: fit-content;
  max-height: calc(100% - 44px - 12px);
  width: 100vw;
  left: 0;
  bottom: 0;

  border-radius: 12px 12px 0 0;
  background-color: variables.$main-black-1;
  animation: appear 0.35s cubic-bezier(0.5, 0, 1, 1);

  overflow: hidden;
  overflow-y: auto;

  &.maxHeight {
    height: calc(100% - 44px - 12px);
  }
}

@keyframes appear {
  0% {
    bottom: -100%;
    opacity: 0;
  }
  100% {
    bottom: 0%;
    opacity: 1;
  }
}
