@use '/src/styles/desktop-variables.scss';

@import '/src/styles/core.scss';

.wrapper {
  position: relative;
  display: inline-flex;
  transition: opacity 0.3s ease-in-out;

  .target {
    display: flex;
    border: none;
    background-color: unset;

    &:hover {
      cursor: pointer;
    }
  }
}

.interactiveTooltip {
  @include flex(center, center, column);
  background-color: transparent;
  color: none;

  .innerContainer {
    background-color: desktop-variables.$main-gray-3;
    border-radius: 0.25rem;
    padding: 0.25rem 0.75rem;
    row-gap: 0.25rem;
  }

  position: fixed;
  border: none;
  z-index: 10;
  outline: none;
}

.interactiveTooltip.show {
  opacity: 1;
  visibility: visible;
}

.interactiveTooltip.hide {
  opacity: 0;
  visibility: hidden;
}
