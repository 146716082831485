@use '/src/styles/variables.scss';

@import '/src/styles/core.scss';

.datePickerTab {
  all: unset;
  @include flex(center, center);
  padding: 10px; // TODO: check with designers
  border-radius: 4px;
  background-color: transparent;
  border: 1px solid variables.$main-gray-1;
  cursor: pointer;
  transition: all 0.2s;

  .title {
    color: variables.$main-white;
  }

  // &:focus {
  //   transition: all 0.2s;
  //   background-color: variables.$main-gray-3;
  // }

  &.selected {
    transition: all 0.2s;
    background-color: variables.$main-gray-3;
  }

  &:disabled {
    transition: all 0.2s;
    opacity: 0.5;
    cursor: not-allowed;
  }
}
