@use '/src/styles/desktop-variables.scss';

@import '/src/styles/core.scss';

.startStage {
  @include flex(flex-start, flex-start, column);
  width: 100%;
  row-gap: 32px;

  .controlButtons {
    width: 100%;
    display: flex;
    column-gap: 20px;

    .btn {
      width: calc(100% - 0.625rem);
    }
  }
}
