@use '/src/styles/variables.scss';
@import '/src/styles/mixins.scss';

.wrapper {
  @include flex(center, flex-start);
  width: 100%;
  column-gap: 6px;

  .text {
    @include flex(center, flex-start);
    width: 100%;
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    opacity: 0.7;
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 150%;
  }

  .filled,
  .dotted {
    width: 10px;
    height: 10px;
    border-radius: 2px;
    transform: translateY(-1px);
  }

  .filled {
    background-color: variables.$main-white;
  }

  .dotted {
    border: 1px dashed variables.$main-white;
  }
}
