@mixin flex($align: center, $justify: center, $direction: row) {
  display: flex;
  justify-content: $justify;
  align-items: $align;
  flex-direction: $direction;
}

@mixin grid($align: center, $justify: center) {
  display: grid;
  align-items: $align;
  justify-content: $justify;
}

@mixin font($color, $size, $weight: 400, $height: $size) {
  font-size: $size;
  font-weight: $weight;
  line-height: $height;
  color: $color;
}

@mixin font-set($size, $weight, $font: $montserrat) {
  font-family: $font;
  font-size: $size;
  font-weight: $weight;
}

@mixin padding($top: 0, $right: 0, $bottom: 0, $left: 0) {
  padding-top: $top;
  padding-right: $right;
  padding-bottom: $bottom;
  padding-left: $left;
}

@mixin margin($top: 0, $right: 0, $bottom: 0, $left: 0) {
  margin-top: $top;
  margin-right: $right;
  margin-bottom: $bottom;
  margin-left: $left;
}

@mixin trim($numLines: null) {
  @if $numLines != null {
    display: -webkit-box;
    -webkit-line-clamp: $numLines;
    -webkit-box-orient: vertical;
    overflow: hidden;
  } @else {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    display: block;
  }
}

