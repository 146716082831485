// Main colors
$main-light-gray: #c9cbd7;
$main-gray-1: #939396;
$main-gray-2: #62626f;
$main-gray-3: #4b4b57;
$main-dark-gray-1: #28282e;
$main-dark-gray-2: #202025;
$main-background: #0f0f11;
$main-white: #f5f5f5;

// Secondary colors.
$secondary-green: #30bb70;
$secondary-red: #f05c5e;
$secondary-yellow: #f1f45e;
$secondary-light-green: #00c3bb;
$secondary-brand-color: #00a19a;
$secondary-dark-green: #007873;
$secondary-turquoise: #e1ffef;

// Linear gradients
$linear-black: linear-gradient(180deg, rgba(30, 30, 33, 0) 0%, #1e1e21 100%);
$linear-green: linear-gradient(180deg, #30bb70 30.78%, rgba(48, 187, 112, 0) 100%);
$linear-white: linear-gradient(180.07deg, #ffffff 44.79%, rgba(255, 255, 255, 0) 78.53%);
$linear-red: linear-gradient(180deg, #f05c5e 30.78%, rgba(240, 92, 94, 0) 100%);

// Angular gradients
$angular-black: conic-gradient(from 90deg at 50% 50%, rgba(0, 0, 0, 0.05) 0deg, #000 359.9639940261841deg);
$angular-white: conic-gradient(from 90deg at 50% 50%, rgba(245, 245, 245, 0) 0deg, #f5f5f5 359.9639940261841deg);

// Diagram colors
$diagram-color-1: #e90101;
$diagram-color-2: #accd00;
$diagram-color-3: #00aee3;
$diagram-color-4: #8b2c90;
$diagram-color-5: #fb5a00;
$diagram-color-6: #00a900;
$diagram-color-7: #0069ba;
$diagram-color-8: #b8015e;
$diagram-color-9: #ffe400;
$diagram-color-10: #01a59c;
$diagram-color-11: #573492;
$diagram-color-12: #f6006d;
$diagram-color-13: #ffed41;
$diagram-color-14: #3fbab5;
$diagram-color-15: #8267ae;
$diagram-color-16: #f74090;
$diagram-color-17: #c1da41;
$diagram-color-18: #42c1ec;
$diagram-color-19: #a760ac;
$diagram-color-20: #ef3f3f;
$diagram-color-21: #3fc041;
$diagram-color-22: #408fca;
$diagram-color-23: #c94088;
$diagram-color-24: #fd8340;
$diagram-color-25: #71d072;
$diagram-color-26: #71a9d8;
$diagram-color-27: #d870a5;
$diagram-color-28: #fca270;
$diagram-color-29: #70ccc7;
$diagram-color-30: #a18dc2;
$diagram-color-31: #fa70ae;
$diagram-color-32: #fff06f;
$diagram-color-33: #6fd1ee;
$diagram-color-34: #bd8ac2;
$diagram-color-35: #f27070;
$diagram-color-36: #d1e471;
$diagram-color-37: #96ddf3;
$diagram-color-38: #cda7d0;
$diagram-color-39: #f79594;
$diagram-color-40: #dcea93;
$diagram-color-41: #94c1e2;
$diagram-color-42: #e093bd;
$diagram-color-43: #feb894;
$diagram-color-44: #94dc93;
$diagram-color-45: #b9aad3;
$diagram-color-46: #fb94c3;
$diagram-color-47: #fbf2a3;
$diagram-color-48: #94d9d4;

// Gray (diagram) colors
$gray-diagram-1: #b1b2af;
$gray-diagram-2: #a8a9a7;
$gray-diagram-3: #9fa09e;
$gray-diagram-4: #979895;
$gray-diagram-5: #8e8f8c;
$gray-diagram-6: #858783;
$gray-diagram-7: #747571;
$gray-diagram-8: #626460;
$gray-diagram-9: #5a5c57;
$gray-diagram-10: #4d4f4a;
$gray-diagram-11: #454742;
$gray-diagram-12: #41423e;
$gray-diagram-13: #3d3e3b;
$gray-diagram-14: #393a37;
$gray-diagram-15: #353633;
$gray-diagram-16: #31322f;
$gray-diagram-17: #2d2e2b;
$gray-diagram-18: #292a27;
$gray-diagram-19: #242523;
$gray-diagram-20: #20211f;
$gray-diagram-21: #1c1d1b;
$gray-diagram-22: #181917;
$gray-diagram-23: #141514;
$gray-diagram-24: #101110;
$gray-diagram-25: #080808;
$gray-diagram-26: #eeeeed;
$gray-diagram-27: #e5e5e4;
$gray-diagram-28: #dcdddc;
$gray-diagram-29: #cbcbca;
$gray-diagram-30: #b9bab8;

// Font families
$montserrat: 'Montserrat', sans-serif;
$work-sans: 'Work Sans', sans-serif;
$road-ua: 'RoadUA', sans-serif;
