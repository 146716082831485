@use '/src/styles/variables.scss';
@import '/src/styles/core.scss';

.progressBar {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 20px;

  border-radius: 4px;
  margin: 8px 0;
  position: relative;

  // Background bar
  &.main {
    background-color: variables.$main-background;
  }

  &.secondary {
    background-color: variables.$secondary-green-1;
  }

  // Active bar
  .main {
    background-color: variables.$secondary-yellow;
  }

  .secondary {
    background-color: variables.$secondary-turquoise;
  }

  .grow {
    padding-top: 2px;
    animation-duration: 1s;
    animation-name: slidein;
    border-radius: 4px;

    height: 100%;
  }

  .percentage {
    @include flex(center, center);
    height: 100%;
    position: absolute;
    right: 0;
    left: 0;
    margin: auto;
    opacity: 0.5;

    animation-duration: 1.2s;
    animation-name: appear;
  }

  .percentageDark {
    color: variables.$main-background;
    opacity: 1;
  }

  .disabled {
    display: none;
  }
}

@keyframes slidein {
  from {
    width: 0;
  }
}

@keyframes appear {
  from {
    opacity: 0;
  }
  50% {
    opacity: 0;
  }
}
