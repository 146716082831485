@use '/src/styles/desktop-variables.scss';

@import '/src/styles/core.scss';

.button {
  @include flex(center, center, row);
  outline: none;
  position: relative;
  cursor: pointer;
  width: fit-content;
  background-color: transparent;
  border: 1px solid transparent;
  transition: all 0.2s;
  outline: unset;
  // margin: -1px 0;

  column-gap: 8px;

  .icon {
    @include flex(center, center);
  }
}

.disabled {
  &:hover {
    cursor: not-allowed;
  }
}

/* SIZES */

.small {
  padding: 8px;

  font-weight: 400;
  font-size: 16px;
  line-height: 150%;

  &.icon {
    @include size(16px, 16px);
  }
}

.medium {
  padding: 8px 12px;

  font-weight: 400;
  font-size: 18px;
  line-height: 150%;

  &.icon {
    @include size(24px, 24px);
  }
}

.large {
  padding: 16px 12px;

  font-weight: 400;
  font-size: 18px;
  line-height: 150%;

  &.icon {
    @include size(24px, 24px);
  }
}

/* VARIANTS */

.main {
  background-color: desktop-variables.$main-dark-gray-2;
  border-color: desktop-variables.$main-white;
  color: desktop-variables.$main-white;
  border-radius: 4px;
  transition: all 0.2s;

  // .icon {
  //   * {
  //     fill: desktop-variables.$main-white;
  //   }
  // }

  &:hover {
    background-color: desktop-variables.$main-gray-2;
    border-color: desktop-variables.$main-white;
    color: desktop-variables.$main-white;
    transition: all 0.2s;

    // .icon {
    //   * {
    //     fill: desktop-variables.$main-white;
    //   }
    // }
  }

  &:focus,
  &.active {
    background-color: desktop-variables.$main-background;
    border-color: desktop-variables.$main-white;
    color: desktop-variables.$main-white;
    transition: all 0.2s;

    // .icon {
    //   * {
    //     fill: desktop-variables.$main-white;
    //   }
    // }
  }

  &:disabled {
    background-color: desktop-variables.$main-dark-gray-2;
    border-color: desktop-variables.$main-white;
    color: desktop-variables.$main-white;
    opacity: 0.5;

    // .icon svg {
    //   * {
    //     fill: desktop-variables.$main-white;
    //   }
    // }
  }
}

.secondary {
  background-color: desktop-variables.$secondary-brand-color;
  color: desktop-variables.$main-white;
  border-radius: 4px;
  transition: all 0.2s;

  // .icon {
  //   * {
  //     fill: desktop-variables.$main-white;
  //   }
  // }

  &:hover {
    background-color: desktop-variables.$secondary-light-green;
    color: desktop-variables.$main-white;
    transition: all 0.2s;

    // .icon {
    //   * {
    //     fill: desktop-variables.$main-white;
    //   }
    // }
  }

  &:focus,
  &.active {
    background-color: desktop-variables.$secondary-dark-green;
    color: desktop-variables.$main-white;
    transition: all 0.2s;

    // .icon {
    //   * {
    //     fill: desktop-variables.$main-white;
    //   }
    // }
  }

  &:disabled {
    background-color: desktop-variables.$secondary-brand-color;
    color: desktop-variables.$main-white;
    opacity: 0.5;

    // .icon svg {
    //   * {
    //     fill: desktop-variables.$main-white;
    //   }
    // }
  }
}

.outline {
  border-color: desktop-variables.$main-gray-3;
  color: desktop-variables.$main-white;
  border-radius: 4px;

  // .icon {
  //   * {
  //     fill: desktop-variables.$main-white;
  //   }
  // }

  &:hover {
    border-color: desktop-variables.$main-gray-3;
    color: desktop-variables.$main-white;

    // .icon {
    //   * {
    //     fill: desktop-variables.$main-white;
    //   }
    // }
  }

  &.active {
    border-color: desktop-variables.$main-gray-3;
    color: desktop-variables.$main-white;

    // .icon {
    //   * {
    //     fill: desktop-variables.$main-white;
    //   }
    // }
  }

  &:disabled {
    border-color: desktop-variables.$main-gray-3;
    color: desktop-variables.$main-white;
    opacity: 0.5;

    // .icon svg {
    //   * {
    //     fill: desktop-variables.$main-white;
    //   }
    // }
  }
}

.borderless {
  color: desktop-variables.$main-white;
  // text-decoration-line: underline;
  border-radius: 4px;

  // .icon {
  //   * {
  //     fill: desktop-variables.$main-white;
  //   }
  // }

  &:hover {
    color: desktop-variables.$main-white;
    // text-decoration-line: underline;
    border-radius: 4px;

    // .icon {
    //   * {
    //     fill: desktop-variables.$main-white;
    //   }
    // }
  }

  &.active {
    color: desktop-variables.$main-white;
    // text-decoration-line: underline;
    border-radius: 4px;

    // .icon {
    //   * {
    //     fill: desktop-variables.$main-white;
    //   }
    // }
  }

  &:disabled {
    color: desktop-variables.$main-white;
    // text-decoration-line: underline;
    border-radius: 4px;
    opacity: 0.5;

    // .icon svg {
    //   * {
    //     fill: desktop-variables.$main-white;
    //   }
    // }
  }
}

.ignoreSVGColor {
  .icon svg,
  svg {
    fill: unset;
    color: unset;
  }
}
