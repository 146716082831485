@import '../../styles/core.scss';

.button {
  @include flex(center, center, row);
  outline: none;
  position: relative;
  cursor: pointer;
  background: transparent;
  touch-action: manipulation;
  justify-content: center;
  background-color: var(--secondary-bg);
  padding: 12px 15px;
  box-sizing: border-box;
  border-radius: 3px;
  transition: 0.2s;
  border: 1px solid transparent;

  &FullWidth {
    width: 100%;
  }

  &Disabled {
    cursor: not-allowed;
    opacity: 0.4;
    transition: none;
  }

  &Outlined {
    background-color: transparent;
    border: 1px solid var(--border-dark);
  }

  &Border {
    border: 1px solid var(--secondary-bg-2);
  }

  &Text {
    background-color: transparent;
    border: 1px solid transparent;
    padding: 12px 15px;
  }
}
