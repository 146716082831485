@use '/src/styles/desktop-variables.scss';
@import '/src/styles/core.scss';

.wrapper {
  @include flex(flex-start, flex-start, column);
  row-gap: 2.5rem;
  width: 100%;

  .stepper {
    position: sticky;
    top: 0;
    padding-bottom: 2.5rem;
    margin-bottom: -2.5rem;
    z-index: 1;
    background-color: desktop-variables.$main-background;
    padding-top: 0.5rem;
  }
}
