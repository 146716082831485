@use '/src/styles/desktop-variables.scss';

@import '/src/styles/core.scss';

.rejectStage {
  @include flex(flex-start, flex-start, column);
  row-gap: 32px;
  width: 100%;

  .content {
    @include flex(center, flex-start);
    column-gap: 12px;
    width: 100%;

    .icon {
      @include flex();
      width: 1.5rem;
      height: 1.5rem;

      * {
        fill: desktop-variables.$secondary-red;
      }
    }
  }

  .btn {
    width: 100%;
  }
}
