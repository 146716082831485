@use '/src/styles/variables.scss';

@import '/src/styles/core.scss';

.settingsTab {
  all: unset;
  @include flex(center, center);
  padding: 0px 9px 12px 9px;
  background-color: transparent;
  border-bottom: 2px solid variables.$main-gray-1;
  white-space: nowrap;
  width: 100%;
  margin-bottom: -2px;

  cursor: pointer;

  .title {
    color: variables.$main-white;
  }

  &.selected {
    padding-bottom: 11px;
    border-bottom: 3px solid variables.$main-white;
  }

  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
}
