@use '/src/styles/variables.scss';

@import '/src/styles/core.scss';

.dropdownItem {
  @include flex(center, flex-start);
  width: 100%;
  column-gap: 8px;
  padding: 9px 24px;
  transition: all 0.2s;
  background-color: variables.$main-gray-3;
  user-select: none;

  .leadingIcon {
    @include flex(center, center);
    // @include fill(variables.$main-white);
  }

  .value {
    color: variables.$main-white;
  }

  &:hover {
    transition: all 0.2s;
    background-color: variables.$main-gray-2;
    cursor: pointer;

    .leadingIcon {
      // @include fill(variables.$main-white);
    }

    .value {
      color: variables.$main-white;
    }
  }

  &.isSelected {
    transition: all 0.2s;
    background-color: variables.$main-dark-gray-1;
    cursor: pointer;

    .leadingIcon {
      @include fill(variables.$main-white);
    }

    .value {
      color: variables.$main-white;
    }
  }
}

.disabled {
  color: rgba(variables.$main-white, 0.7);

  &:hover {
    transition: all 0.2s;
    cursor: not-allowed;
  }
}
