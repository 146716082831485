@use '/src/styles/desktop-variables.scss';

@import '/src/styles/core.scss';

.wrapper {
  display: grid;
  width: 100%;
  grid-auto-columns: 1fr;
  grid-auto-flow: column;
  background-color: desktop-variables.$main-dark-gray-1;
  border-radius: 0.375rem;

  .section {
    display: grid;
    grid-auto-flow: row;
    row-gap: 0.75rem;
    width: 100%;
    padding: 0 1.5rem;
    margin: 1.25rem 0;

    .label {
      text-transform: uppercase;
      opacity: 0.5;
    }

    &:not(:last-child) {
      border-right: 1px solid desktop-variables.$main-gray-3;
    }
  }
}
