@use '/src/styles/variables.scss';

@import '/src/styles/core.scss';

.wrapper {
  display: flex;
  flex-direction: column;
  height: 100%;

  .content {
    display: flex;
    flex-direction: column;
    overflow: hidden;
    overflow-y: auto;
    padding: 0 20px;

    .divider {
      border-bottom: 1px solid variables.$main-gray-3;
      width: 100%;
    }
  }

  .header {
    display: flex;
    justify-content: space-between;
    padding: 24px 20px 16px;

    .icon {
      cursor: pointer;
    }
  }

  .period {
    padding: 24px 0;

    .button {
      width: 100%;
      justify-content: flex-start;
    }
  }

  .readStatuses {
    display: flex;
    flex-direction: column;
    row-gap: 20px;
    padding: 24px 0;
  }

  .statuses {
    display: flex;
    flex-direction: column;
    padding: 24px 0;
    row-gap: 20px;

    &.disabled {
      opacity: 0.5;
    }

    .label {
      display: flex;
      justify-content: space-between;
    }

    .statusItem {
      position: relative;

      .indicator {
        position: absolute;
        top: 0;
        right: 0;
      }
    }
  }

  .controlButtons {
    display: flex;
    justify-content: space-between;
    padding: 16px 20px 40px;
    column-gap: 20px;
    margin-top: auto;

    & > button {
      width: 100%;
    }
  }

  .tooltipContent {
    @include flex(flex-start, flex-start, column);
    row-gap: 1rem;
    width: 100%;

    .tooltipItem {
      @include flex(center, flex-start);
      column-gap: 0.75rem;
      width: 100%;

      > svg {
        min-width: fit-content;
      }
    }
  }
}
