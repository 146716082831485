@use './variables.scss';
@import './normalize.css';
@import './core.scss';

body {
  background-color: variables.$main-background;
  color: variables.$main-white;
  position: relative;

  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'RoadUA', 'sans';
  }

  a {
    text-decoration: unset;
  }
}

/* Custom scrollbar on hover */
::-webkit-scrollbar {
  width: 4px;
  display: none;
}

/* Background */
::-webkit-scrollbar-track {
  background: #202025;
  border-radius: 5px;
  display: none;
}

/* Scroll */
::-webkit-scrollbar-thumb {
  background: #4b4b57;
  border-radius: 5px;
  display: none;
}
