@use '/src/styles/variables.scss';

@import '/src/styles/core.scss';

.accordionHeader {
  display: flex;
  flex-direction: column;
  // row-gap: 1rem;
  width: 100%;
  padding-bottom: 0;
  border-bottom: 0px solid transparent;
  transition: all 0.3s;
  cursor: pointer;

  .wrapperHeader {
    display: flex;
    flex-direction: column;
    justify-content: center;
    // row-gap: 0.75rem;
    width: 100%;

    .control {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
  }

  .wrapperBottom {
    display: flex;
    flex-direction: column;
    row-gap: 12px;

    .row {
      display: flex;
      flex-wrap: wrap;

      .item {
        flex-basis: calc((100% / 3));
        opacity: 0.5;
        text-align: center;

        &:nth-child(3n - 2) {
          text-align: left;
        }

        &:nth-child(3n) {
          text-align: right;
        }
      }

      .first {
        justify-content: flex-start;
        text-transform: uppercase;
      }
    }
  }

  .icon,
  > svg {
    @include size(24px, 24px);
    max-width: 24px;
    max-height: 24px;
  }

  .on {
    transform: rotate(180deg);
    transition: 0.3s;
  }

  .off {
    transform: rotate(0deg);
    transition: 0.3s;
  }
}

.expandedHeader {
  // border-bottom: 1px solid variables.$main-gray-3;
  border-bottom: 1px solid variables.$main-gray-4;
  transition: all 0.3s;
  padding-bottom: 1rem;
}

.expandedHeaderWithoutLine {
  transition: all 0.3s;
}
