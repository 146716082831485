@use '/src/styles/desktop-variables.scss';
@import '/src/styles/core.scss';

.wrapper {
  @include flex(flex-start, flex-start, column);
  row-gap: 2.5rem;
  width: 100%;

  .form {
    @include flex(flex-start, flex-start, column);
    row-gap: 1.25rem;
    width: 100%;

    button {
      width: 100%;
      margin-top: 1.25rem;
    }
  }

  .stepper {
    position: sticky;
    top: 0;
    padding-bottom: 2.5rem;
    margin-bottom: -2.5rem;
    z-index: 1;
    background-color: desktop-variables.$main-background;
  }

  .controls {
    position: sticky;
    bottom: 0;
    @include flex();
    width: 100%;
    background-color: desktop-variables.$main-background;

    button {
      width: 100%;
    }
  }
}
