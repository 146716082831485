@use '/src/styles/variables.scss';
@import '/src/styles/core.scss';

.notification {
  display: flex;
  flex-direction: column;
  row-gap: 12px;
  border-bottom: 1px solid variables.$main-dark-gray-1;
  padding: 20px;

  &.dark {
    background-color: variables.$main-dark-gray-2;
  }

  &.light {
    background-color: variables.$main-dark-gray-3;
  }

  .header {
    display: flex;
    flex-direction: column;
    row-gap: 8px;

    .messageDate {
      display: flex;
      justify-content: space-between;

      > :first-child {
        opacity: 0.5;
      }
    }

    .levelType {
      opacity: 0.9;
    }
  }

  .textContent {
    opacity: 0.7;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    color: variables.$main-white;
    height: 21px;
    max-width: 100%;

    &.isOpened {
      height: 100%;
      display: block;
      -webkit-line-clamp: unset;
      -webkit-box-orient: unset;
    }
  }

  .readMoreButton {
    padding: 0.5rem 0;
  }

  .pollButton {
    width: 100%;
    padding: 12px 0;
  }

  .link {
    text-decoration: underline;
    color: inherit;
    background-color: inherit;
    font-size: inherit;
    line-height: inherit;
  }
}
