@use '/src/styles/desktop-variables.scss';

@import '/src/styles/core.scss';

.confirmationStage {
  @include flex(flex-start, flex-start, column);
  row-gap: 32px;
  width: 100%;

  .content {
    @include flex(center, flex-start);
    width: 100%;
    column-gap: 12px;
  }

  .controlButtons {
    display: flex;
    width: 100%;
    column-gap: 20px;

    .btn {
      width: calc(100% - 0.625rem);
    }
  }
}
