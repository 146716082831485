@import '/src/styles/core.scss';

.wrapper {
  @include flex(flex-start, flex-start, column);
  row-gap: 2.5rem;
  width: 100%;

  .controlButtons {
    @include flex(center, space-between);
    column-gap: 1.25rem;
    width: 100%;

    button,
    a {
      width: calc(50% - 0.625rem);

      button {
        width: 100%;
      }
    }
  }
}
