@import '/src/styles/core.scss';

.requestServiceAction {
  @include flex(flex-start, flex-start, column);
  row-gap: 2rem;
  width: 100%;

  .content {
    @include flex(flex-start, space-between);
    column-gap: 0.75rem;
  }

  .message {
    @include flex(flex-start, space-between, column);
    row-gap: 1rem;
  }

  .button {
    width: 100%;
  }
}
