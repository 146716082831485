@use '/src/styles/desktop-variables.scss';

@import '/src/styles/core.scss';

.mainLayout {
  display: grid;
  grid-template-rows: auto 1fr;
  grid-template-columns: 15rem 1fr;
  width: 100%;
  height: 100vh;
  overflow-y: hidden;
  transition: grid-template-columns 0.2s ease;

  @include laptop-xl {
    grid-template-columns: 17.5rem 1fr;
  }

  @include laptop-giant {
    grid-template-columns: 20rem 1fr;
  }

  @include laptop-extreme {
    grid-template-columns: 22.5rem 1fr;
  }

  &.collapsed {
    grid-template-columns: 4rem 1fr;
  }

  .innerWrapper {
    display: flex;
  }

  .content {
    display: flex;
    max-height: calc(100vh - 78px);
    overflow: hidden;
    overflow-x: visible;
    overflow-y: auto;
    padding: 0 1.5rem;
    margin: 1.5rem 0 0;

    .restriction {
      position: absolute;
      top: 0;
      left: 0;
      z-index: 4;
      @include flex(center, center);
      column-gap: 0.5rem;
      padding: 0.25rem;
      background-color: desktop-variables.$secondary-red;
      width: 100%;
    }
  }

  .roleIconMessage {
    position: relative;
    @include flex;

    &--indicator::before {
      content: '';
      position: absolute;
      right: -2px;
      top: -2px;
      width: 0.5rem;
      height: 0.5rem;
      border-radius: 50%;
      background-color: desktop-variables.$secondary-yellow;
    }
  }
}
