@use '/src/styles/desktop-variables.scss';
@import '/src/styles/core.scss';

.wrapper {
  @include flex(flex-start, flex-start, column);
  row-gap: 2.5rem;
  width: 100%;

  .subtitleRow {
    @include flex(center, space-between);
    column-gap: 1rem;
    width: 100%;

    > :first-child {
      opacity: 0.8;
    }
  }

  .content {
    @include flex(flex-start, flex-start, column);
    row-gap: 1.5rem;
    width: 100%;

    .capitalItem {
      @include flex(center, flex-start);
      width: 100%;
    }

    button {
      width: fit-content;
      padding: 0.5rem 0;
    }
  }

  button {
    width: 100%;
  }

  .controls {
    @include flex(center, space-between);
    column-gap: 1.25rem;
    width: 100%;

    button {
      width: calc(50% - 0.625rem);
    }
  }

  .stepper {
    background-color: desktop-variables.$main-background;
    padding-top: 0.5rem;
  }
}
