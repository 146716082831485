@use '/src/styles/desktop-variables.scss';

@import '/src/styles/core.scss';

.rdrCalendarWrapper {
  box-sizing: border-box;
  background: desktop-variables.$main-dark-gray-2;
  display: inline-flex;
  flex-direction: column;
  user-select: none;
}

.rdrDateDisplay {
  display: flex;
  justify-content: space-between;
}

.rdrDateDisplayItem {
  flex: 1 1;
  width: 0;
  text-align: center;
  color: inherit;
  & + & {
    margin-left: 0.833em;
  }
  input {
    text-align: inherit;
    &:disabled {
      cursor: default;
    }
  }
}

.rdrMonthAndYearWrapper {
  box-sizing: inherit;
  display: flex;
  justify-content: space-between;
}

.rdrMonthAndYearPickers {
  flex: 1 1 auto;
  display: flex;
  justify-content: center;
  align-items: center;
}

.rdrNextPrevButton {
  box-sizing: inherit;
  cursor: pointer;
  outline: none;
}

.rdrMonths {
  display: flex;
}

.rdrMonthsVertical {
  flex-direction: column;
}

.rdrMonthsHorizontal > div > div > div {
  display: flex;
  flex-direction: row;
}

.rdrMonth {
  width: 444px;
}

.rdrWeekDays {
  display: flex;
}

.rdrWeekDay {
  flex-basis: calc(100% / 7);
  box-sizing: inherit;
  text-align: center;
  height: 48px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  line-height: 150%;
}

.rdrDays {
  display: flex;
  flex-wrap: wrap;
}

.rdrInfiniteMonths {
  overflow: auto;
}

.rdrSelected {
  left: calc(50% - 16px) !important;
  top: calc(50% - 16px) !important;
  width: 32px;
  height: 32px;
  border-radius: 4px;
  border: 1px solid #939396;
}

.rdrDayDisabled {
  visibility: hidden;
}

.rdrMonthAndYearPickers {
  button {
    padding: 8px 16px;
  }
}
