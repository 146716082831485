@use '/src/styles/desktop-variables.scss';
@import '/src/styles/core.scss';

.wrapper {
  position: relative;

  .tooltip {
    position: absolute;
    z-index: 10;
    display: none;
    min-width: fit-content;
    height: fit-content;
    background-color: desktop-variables.$main-gray-3;
    color: desktop-variables.$main-white;
    overflow: visible;
    text-overflow: initial;
    white-space: nowrap;
    padding: 0.5rem 1rem;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    border-radius: 0.375rem;
  }

  .triangle {
    position: absolute;
    z-index: 10;
    display: none;
    width: 14px;
    height: 6px;
    background-color: desktop-variables.$main-gray-3;
    clip-path: polygon(50% 0%, 0% 100%, 100% 100%);
    border-radius: 100% 100% 0 0;
  }

  &:hover .tooltip,
  &:hover .triangle {
    display: block;
  }
}
