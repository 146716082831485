@use '/src/styles/desktop-variables.scss';

@import '/src/styles/core.scss';

.slider {
  @include flex(flex-start, flex-start);
  column-gap: 6px;
  width: 100%;

  .item {
    @include size(6px, 6px);
    border-radius: 50%;
    background-color: desktop-variables.$main-gray-1;
    opacity: 0.5;
    transition: all 0.2s;
  }

  .active {
    opacity: 1;
    transition: all 0.2s;
  }
}
