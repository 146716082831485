@use '/src/styles/desktop-variables.scss';
@import '/src/styles/core.scss';

.complexLineChart {
  width: 100%;
  height: 300px;

  display: flex;
  flex-direction: column;

  .complexLineChartContainer {
    width: 100%;
    height: 100%;
  }

  .complexLineChartDrawdownMargin {
    margin-top: -10px;
  }
}

.darkFont {
  color: desktop-variables.$main-dark-gray-1 !important;
}

.tooltipContainer {
  @include flex(flex-start, flex-start, column);
}

.complexLineTooltip {
  position: relative;
  padding: 2px 3px 1px 5px;
  width: fit-content;
  background: #30bb70;
  border-radius: 4px;
  color: desktop-variables.$main-dark-gray-1 !important;
  margin-top: 10px;
}

.complexLineTooltip2 {
  position: relative;
  padding: 2px 3px 1px 5px;
  width: fit-content;
  background: desktop-variables.$secondary-green;
  border-radius: 4px;
}

.customLegend {
  border: 1px solid transparent;
  margin-top: 10px;
  grid-gap: 16px;
  display: grid;
  grid-template-columns: repeat(3, auto);
  justify-content: start;
  margin-bottom: 30px;
}

.green,
.white,
.dashed {
  display: block;
  width: 8px;
  height: 8px;
  margin-right: 8px;
  border-radius: 2px;
}
.green {
  background: desktop-variables.$secondary-green;
}

.white {
  background: desktop-variables.$main-white;
}

.dashed {
  border: 1px dashed desktop-variables.$main-white;
}

.labelWhite {
  fill: desktop-variables.$main-white;
  font-weight: 600;
  font-size: 10px;
}

.project {
  fill: '#000';
  background-color: '#FFFFFF';
  padding: '5px';
  width: '100%';
  height: '20px';
  border-radius: '5px';
  font-weight: 600;
  font-size: '20px';
}
