@use '/src/styles/variables.scss';
@import '/src/styles/core.scss';

.wrapper {
  width: 100%;
  height: 48px;
  display: flex;
  justify-content: center;
  align-items: center;

  .circleWrapper {
    display: flex;
    width: 48px;
    height: 48px;
    padding: 10px;
    justify-content: center;
    align-items: center;
    border-radius: 100px;

    &.success {
      background: rgba(48, 187, 112, 0.1);

      .icon {
        @include size(28px, 28px);
        * {
          opacity: 1;
          fill: variables.$secondary-green-1;
        }
        animation: crop 0.5s cubic-bezier(0.5, 0, 1, 1) forwards;
      }
    }

    &.loading {
      background: rgba(241, 244, 94, 0.1);
    }

    &.error {
      animation: growCircle 0.25s cubic-bezier(0.5, 0, 1, 1) forwards;
      background: rgba(240, 92, 94, 0.1);
    }
  }
}

@keyframes growCircle {
  0% {
    width: 0;
    height: 0;
    opacity: 0;
  }
  100% {
    width: 48px;
    height: 48px;
    opacity: 1;
  }
}

@keyframes crop {
  0% {
    clip-path: polygon(0 0, 0 100%, 0 100%, 0 0);
  }
  100% {
    clip-path: polygon(0 0, 0 100%, 100% 100%, 100% 0);
  }
}
