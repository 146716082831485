@use '/src/styles/desktop-variables.scss';
@import '/src/styles/core.scss';

.assetTileBody {
  @include flex(flex-start, flex-start, column);
  width: 100%;
  padding: 0 32px;

  &:last-child {
    .hr {
      display: none;
    }

    .last {
      padding-bottom: 24px;
    }
  }

  .block {
    display: flex;
    width: 100%;
    justify-content: space-between;

    > div:first-child {
      font-weight: 400;
    }
  }

  .gray {
    color: desktop-variables.$main-white;
    opacity: 0.6;
    > div {
      font-weight: 400;
    }
  }

  .green {
    color: desktop-variables.$secondary-green;
  }

  .red {
    color: desktop-variables.$secondary-red;
  }

  .pb18 {
    padding-bottom: 18px;
  }

  .pb16 {
    padding-bottom: 16px;
  }

  .pb12 {
    padding-bottom: 12px;
  }

  .pb24 {
    padding-bottom: 24px;
  }

  .mb26 {
    margin-bottom: 26px;
  }

  .row {
    display: flex;
    gap: 5px;
  }

  .hr {
    background: desktop-variables.$main-gray-3;
    border: none;
    height: 1px;
    width: 100%;
  }

  .mb20 {
    margin-bottom: 20px;
  }
}
