@use '/src/styles/variables.scss';
@import '/src/styles/core.scss';

.wrapper {
  @include flex(center, flex-start);
  column-gap: 0.5rem;
  width: fit-content;
  -webkit-tap-highlight-color: transparent;
  cursor: pointer;
  transition: all 0.2s;

  .customRadio {
    position: relative;
    @include flex(center, center);
    width: 24px;
    height: 24px;
    border: 2px solid variables.$main-gray-3;
    border-radius: 50%;
    transition: all 0.2s;

    @include laptop-s() {
      &:hover {
        transition: all 0.2s;
        border-color: #5d5d6c;
      }
    }
  }

  .customRadio::after {
    display: block;
    visibility: hidden;
    opacity: 0;
    content: '';
    width: 12px;
    height: 12px;
    background: variables.$main-white;
    position: absolute;
    border-radius: 50%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    transition: all 0.2s;
  }

  .radioInput:checked ~ .customRadio {
    border-color: rgba(variables.$main-white, 0.5);
    transition: all 0.2s;

    @include laptop-s() {
      &:hover {
        transition: all 0.2s;
        border-color: #a9a9ab;

        &:after {
          transition: all 0.2s;
          background-color: #a9a9ab;
          opacity: 1;
        }
      }
    }

    &:after {
      visibility: visible;
      transition: all 0.2s;
      opacity: 0.5;
    }
  }
}

.disabledWrapper {
  cursor: not-allowed;
  opacity: 0.7;

  &:hover {
    .customRadio {
      pointer-events: none;
    }
  }
}
