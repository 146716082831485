@use '/src/styles/variables.scss';

@import '/src/styles/core.scss';

.wrapper {
  display: flex;
  width: 100%;

  & > button {
    // width: 100%;
    width: fit-content;
  }

  .link,
  & > * {
    display: flex;
    justify-content: center;
    width: 100%;
    text-decoration: unset;

    button {
      width: 100%;
    }

    &:visited {
      color: inherit;
    }
  }
}

.settingsMenuWrapper {
  border-bottom: 2px solid variables.$main-dark-gray-1;
}
