@import '/src/styles/core.scss';

.wrapper {
  @include flex(flex-start, flex-start, column);
  row-gap: 2.5rem;
  width: 100%;

  .info {
    @include flex(center, space-between);
    column-gap: 1rem;
    width: 100%;

    .label {
      opacity: 0.8;
    }
  }

  .formWrapper {
    @include flex(flex-start, flex-start, column);
    row-gap: 2.5rem;
    width: 100%;

    .fields {
      @include flex(flex-start, flex-start, column);
      row-gap: 1.5rem;
      width: 100%;

      button {
        width: fit-content;
      }
    }

    button {
      width: 100%;
    }
  }
}
