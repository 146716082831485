@use '/src/styles/desktop-variables.scss';

@import '/src/styles/core.scss';

.rdrCalendarWrapper {
  font-size: 12px;
}

.rdrDateDisplayWrapper {
  width: 444px;
  height: 40px;
  background-color: desktop-variables.$main-dark-gray-2;
  border-radius: 4px;
  display: flex;
  align-items: center;
  margin-bottom: 24px;

  button {
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding: 0 16px;
  }
}

.rdrDateDisplay {
  margin: 0.833em;
}

.rdrDateDisplayItem {
  border-radius: 4px;
  border: 1px solid transparent;
  input {
    cursor: pointer;
    height: 2.5em;
    line-height: 2.5em;
    border: 0px;
    background: transparent;
    width: 100%;
  }
}

.rdrDateDisplayItemActive {
  border-color: currentColor;
}

.rdrDateDisplayItemActive {
  input {
    color: #7d888d;
  }
}

.rdrMonthAndYearWrapper {
  align-items: center;
  height: 60px;
  padding-top: 10px;
}

.rdrMonthPicker,
.rdrYearPicker {
  margin: 0 10px;
}

.rdrNextPrevButton {
  cursor: pointer;
  margin: 0 1.833em;
}

.rdrWeekDays {
  padding: 0 0.833em;
}

.rdrMonth {
  padding: 0 0.833em 1.666em 0.833em;
  .rdrWeekDays {
    padding: 0;
  }
}

.rdrMonths.rdrMonthsVertical .rdrMonth:first-child .rdrMonthName {
  display: none;
}

.rdrWeekDay {
  font-weight: 400;
  line-height: 150%;
}

.rdrDay {
  background: transparent;
  user-select: none;
  border: 0;
  padding: 0;
  line-height: 3em;
  height: 3em;
  text-align: center;
  height: 48px;
  &:focus {
    outline: 0;
  }
}

.rdrDayNumber {
  outline: 0;
  font-weight: 300;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  top: 5px;
  bottom: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.rdrDayToday .rdrDayNumber span {
  font-weight: 400;
  &:after {
    content: '';
    position: absolute;
    bottom: 4px;
    left: 50%;
    transform: translate(-50%, 0);
    width: 18px;
    height: 2px;
    border-radius: 2px;
  }
}

.rdrDayToday:not(.rdrDayPassive) {
  .rdrInRange,
  .rdrStartEdge,
  .rdrEndEdge,
  .rdrSelected {
    & ~ .rdrDayNumber span:after {
      background: #fff;
    }
  }
}

.rdrDay:not(.rdrDayPassive) {
  .rdrInRange,
  .rdrStartEdge,
  .rdrEndEdge,
  .rdrSelected {
    & ~ .rdrDayNumber {
      span {
        color: rgba(255, 255, 255, 0.85);
      }
    }
  }
}

.rdrSelected,
.rdrInRange,
.rdrStartEdge,
.rdrEndEdge {
  background: currentColor;
  position: absolute;
  top: 7px;
  left: 0;
  right: 0;
  bottom: 7px;
  border-top: 1px solid desktop-variables.$main-gray-1;
  border-bottom: 1px solid desktop-variables.$main-gray-1;
}

.rdrSelected {
  left: 2px;
  right: 2px;
}

.rdrStartEdge {
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  border-color: desktop-variables.$main-gray-1;
  border-left: 1px solid desktop-variables.$main-gray-1;
  left: 2px;
}

.rdrEndEdge {
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  border-color: desktop-variables.$main-gray-1;
  border-right: 1px solid desktop-variables.$main-gray-1;
  right: 2px;
}

.rdrDayStartOfMonth,
.rdrDayStartOfWeek {
  .rdrInRange,
  .rdrEndEdge {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    left: 2px;
  }
}

.rdrDayEndOfMonth,
.rdrDayEndOfWeek {
  .rdrInRange,
  .rdrStartEdge {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    right: 2px;
  }
}

.rdrDayStartOfMonth,
.rdrDayStartOfWeek {
  .rdrDayInPreview,
  .rdrDayEndPreview {
    border-top-left-radius: 1.333em;
    border-bottom-left-radius: 1.333em;
    border-left-width: 1px;
    left: 0px;
  }
}

.rdrDayEndOfMonth,
.rdrDayEndOfWeek {
  .rdrDayInPreview,
  .rdrDayStartPreview {
    right: 0px;
  }
}

.rdrDayStartPreview,
.rdrDayInPreview,
.rdrDayEndPreview {
  position: absolute;
  top: 1px;
  left: 0px;
  right: 0px;
  bottom: 1px;
  pointer-events: none;
  border: 0px solid currentColor;
  z-index: 1;
}

.rdrDayStartPreview {
  border-top-width: 1px;
  border-left-width: 1px;
  border-bottom-width: 1px;
  border-top-left-radius: 1.333em;
  border-bottom-left-radius: 1.333em;
  left: 0px;
}

.rdrDayInPreview {
  border-top-width: 1px;
  border-bottom-width: 1px;
}

.rdrDayEndPreview {
  border-top-width: 1px;
  border-right-width: 1px;
  border-bottom-width: 1px;
  border-top-right-radius: 1.333em;
  border-bottom-right-radius: 1.333em;
  right: 2px;
  right: 0px;
}

.rdrDefinedRangesWrapper {
  font-size: 12px;
  width: 226px;
  border-right: solid 1px #eff2f7;
  background: #fff;
  .rdrStaticRangeSelected {
    color: currentColor;
    font-weight: 600;
  }
}

.rdrStaticRange {
  border: 0;
  cursor: pointer;
  display: block;
  outline: 0;
  border-bottom: 1px solid #eff2f7;
  padding: 0;
  background: #fff;
}

.rdrStaticRangeLabel {
  display: block;
  outline: 0;
  line-height: 18px;
  padding: 10px 20px;
  text-align: left;
}

.rdrInputRanges {
  padding: 10px 0;
}

.rdrInputRange {
  align-items: center;
  padding: 5px 20px;
}

.rdrInputRangeInput {
  width: 30px;
  height: 30px;
  line-height: 30px;
  border-radius: 4px;
  text-align: center;
  border: solid 1px rgb(222, 231, 235);
  margin-right: 10px;
  color: rgb(108, 118, 122);
}

.rdrCalendarWrapper:not(.rdrDateRangeWrapper) .rdrDayHovered .rdrDayNumber:after {
  content: '';
  border: 1px solid currentColor;
}

.rdrDayPassive {
  pointer-events: none;
  .rdrDayNumber span {
    color: desktop-variables.$main-dark-gray-2;
  }
  .rdrInRange,
  .rdrStartEdge,
  .rdrEndEdge,
  .rdrSelected,
  .rdrDayStartPreview,
  .rdrDayInPreview,
  .rdrDayEndPreview {
    display: none;
  }
}

.rdrDayDisabled {
  background-color: rgb(248, 248, 248);
  .rdrDayNumber span {
    color: #aeb9bf;
  }
  .rdrInRange,
  .rdrStartEdge,
  .rdrEndEdge,
  .rdrSelected,
  .rdrDayStartPreview,
  .rdrDayInPreview,
  .rdrDayEndPreview {
    filter: grayscale(100%) opacity(60%);
  }
}

.rdrMonthName {
  text-align: left;
  font-weight: 600;
  color: #849095;
  padding: 0.833em;
}
