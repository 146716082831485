@use '/src/styles/variables.scss';

@import '/src/styles/core.scss';

.wrapper {
  @include flex(center, center);
  width: fit-content;
  padding: 2px 8px;
  background-color: variables.$main-dark-gray-1;
  border-radius: 0.25rem;
  font-size: 0.75rem;
  line-height: 1.125rem;
  font-weight: 400;
  color: variables.$secondary-yellow;
}
