@font-face {
  src: url('../assets/fonts/road-ua/RoadUA-Regular.woff2') format('truetype');
  font-family: 'RoadUA';
  font-weight: 400;
}

@font-face {
  src: url('../assets/fonts/road-ua/RoadUA-Medium.woff2') format('truetype');
  font-family: 'RoadUA';
  font-weight: 500;
}

@font-face {
  src: url('../assets/fonts/road-ua/RoadUA-Bold.woff2') format('truetype');
  font-family: 'RoadUA';
  font-weight: 700;
}

@font-face {
  src: url('../assets/fonts/road-ua/RoadUA-ExtraBold.woff2') format('truetype');
  font-family: 'RoadUA';
  font-weight: 800;
}
