@use '/src/styles/variables.scss';

@import '/src/styles/core.scss';

.wrapper {
  position: relative;
  @include flex(flex-start, flex-start, column);
  width: fit-content;

  div[role='button'] {
    @include flex(center, center);
    width: 100%;
    height: 100%;
    cursor: pointer;
  }

  &.disabled {
    div[role='button'] {
      cursor: not-allowed;
    }
  }
}

.dropdownMenu {
  position: fixed;
  z-index: variables.$z-dropdown;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: fit-content;
  overflow: hidden;

  &.isVisible {
    visibility: visible;
  }

  &.isHidden {
    visibility: hidden;
  }

  &.heightLimited {
    max-height: 250px;
  }
}
