@use '/src/styles/desktop-variables.scss';
@import '/src/styles/core.scss';

.wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  background: desktop-variables.$main-dark-gray-1;
  border-radius: 6px;
  transition: all 0.3s;
  cursor: pointer;

  &:hover {
    transition: all 0.3s;
  }

  .extendedData {
    @include flex(flex-start, flex-start, column);
    row-gap: 1.5rem;
    width: 100%;
  }
}
