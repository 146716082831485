@use '/src/styles/variables.scss';
@use '/src/styles/desktop-variables.scss';

@import '/src/styles/core.scss';

.negative {
  color: variables.$secondary-red-1;

  @include laptop-s() {
    color: desktop-variables.$secondary-red;
  }
}

.positive {
  color: variables.$secondary-green-1;

  @include laptop-s() {
    color: desktop-variables.$secondary-green;
  }
}

.neutral {
  color: variables.$main-white;

  @include laptop-s() {
    color: desktop-variables.$main-white;
  }
}
