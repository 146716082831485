@use '/src/styles/desktop-variables.scss';

@import '/src/styles/core.scss';

.settingsTab {
  all: unset;
  @include flex(center, center);
  padding: 0px 9px 12px 9px;
  background-color: transparent;
  border-bottom: 2px solid desktop-variables.$main-dark-gray-1;
  white-space: nowrap;
  width: 100%;
  margin-bottom: -2px;

  .title {
    color: desktop-variables.$main-white;
  }

  &:hover {
    cursor: pointer;
  }

  &.selected {
    padding-bottom: 11px;
    border-bottom: 3px solid desktop-variables.$main-gray-3;
  }

  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
}
