@import '/src/styles/core.scss';

.wrapper {
  display: flex;
  flex-direction: column;
  row-gap: 2.5rem;
  width: 100%;

  .button {
    width: 100%;
  }
}
