@use '/src/styles/variables.scss';
@import '/src/styles/mixins.scss';

.wrapper {
  all: unset;
  display: flex;
  justify-content: space-between;
  width: calc(100% - 32px);
  align-items: center;
  padding: 12px 16px;
  transition: all 0.2s;
  background-color: unset;

  &:focus-within,
  &:focus {
    transition: all 0.2s;
    background-color: variables.$main-dark-gray-2;
  }

  &.bottomBorder {
    border-bottom: 1px solid variables.$main-gray-5;
  }

  .leftBlock {
    display: flex;
    justify-content: center;
    align-items: center;
    column-gap: 12px;

    .icon {
      display: flex;
      width: 44px;
      height: 44px;
      justify-content: center;
      align-items: center;
      border-radius: 100px;
      background-color: variables.$main-dark-gray-1;

      > svg {
        @include size(20px, 20px);
      }
    }

    div {
      text-transform: unset;
    }
  }

  .rightControls {
    display: flex;
    justify-content: center;
    align-items: center;
    column-gap: 0.75rem;
    transition: 0.4s;

    .icon,
    .icon > svg,
    .icon > img {
      @include size(20px, 20px);
      object-fit: contain;
    }
  }

  .icon {
    opacity: 0.6;
  }

  .isActive {
    opacity: 1;
    transition: 0.4s;
  }
}
