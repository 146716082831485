@use '/src/styles/variables.scss';

@import '/src/styles/core.scss';

.tooltip {
  position: relative;
  display: inline-flex;
  transition: opacity 0.3s ease-in-out;

  .target {
    display: flex;
    border: none;
    background-color: unset;

    &:hover {
      cursor: pointer;
    }
  }

  .centerContainer {
    position: absolute;
    left: 50%;
    transform: translateX(-6%);
    bottom: calc(100% + 15px);
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .content {
    position: relative;
    background-color: variables.$main-gray-3;
    border-radius: 6px;
    padding: 12px 16px;
    // height: 86px;
    width: calc(100vw - 40px);
    color: variables.$main-white;

    &::after {
      content: '';
      position: absolute;
      left: 7.5px;
      top: 100%;
      width: 1px;
      border-width: 7.5px;
      border-style: solid;
      border-color: variables.$main-gray-3 transparent transparent transparent;
    }

    &.bottom {
      &::after {
        top: unset;
        bottom: 100%;
        left: 7.5px;
        width: 1px;
        border-color: transparent transparent variables.$main-gray-3 transparent;
        border-width: 7.5px;
        border-style: solid;
      }
    }

    &.left {
      &::after {
        left: 100%;
        right: unset;
        top: calc(50% - 7.5px);
        border-width: 6.5px;
        width: 1px;
        border-color: transparent transparent transparent variables.$main-gray-3;
      }
    }

    &.right {
      &::after {
        right: 100%;
        left: unset;
        top: calc(50% - 7.5px);
        border-width: 6.5px;
        width: 1px;
        border-color: transparent variables.$main-gray-3 transparent transparent;
      }
    }

    &.topLeft {
      &:after {
        right: 6px;
        left: unset;
        bottom: 0;
        border-width: 6.5px;
        width: 1px;
        border-color: transparent variables.$main-gray-3 transparent transparent;
        transform: rotateZ(270deg);
      }
    }
  }
}

.tooltip.show {
  opacity: 1;
  visibility: visible;
}

.tooltip.hide {
  opacity: 0;
  visibility: hidden;
}

.top {
  bottom: calc(100% + 5px);
}

.topLeft {
  bottom: calc(100% + 5px);
  right: calc(100% - 22px);
}

.bottom {
  bottom: unset !important;
  top: calc(100% + 15px);
}

.left {
  top: 50%;
  right: calc(100% + 15px);
  transform: translateX(0);
  margin-right: 0;
  width: max-content;
}

.right {
  top: 50%;
  left: 12.5%;
  transform: translateX(0);
  margin-left: 0;
  width: max-content;
}
