@import '../../styles/core.scss';

.icon {
  cursor: pointer;

  &Checker {
    padding-right: 10px;
  }

  &Cursor {
    cursor: default;
  }
}
