@mixin mobile() {
  @media (max-width: 767px) {
    @content;
  }
}

@mixin tablet() {
  @media (min-width: 768px) {
    @content;
  }
}

@mixin laptop-s() {
  @media (min-width: 1024px) {
    @content;
  }
}

@mixin laptop() {
  @media (min-width: 1280px) {
    @content;
  }
}

@mixin laptop-l() {
  @media (min-width: 1440px) {
    @content;
  }
}

@mixin laptop-xl() {
  @media (min-width: 1600px) {
    @content;
  }
}

@mixin laptop-giant() {
  @media (min-width: 1920px) {
    @content;
  }
}

@mixin laptop-extreme() {
  @media (min-width: 2200px) {
    @content;
  }
}
