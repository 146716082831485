@use '/src/styles/desktop-variables.scss';
@import '/src/styles/core.scss';

.stepper {
  @include flex(flex-start, flex-start);
  column-gap: 16px;
  width: 100%;
  max-width: 444px;

  .tab {
    @include flex(flex-start, flex-start, column);
    row-gap: 0.375rem;
    width: 100%;
    padding-top: 0.125rem;

    .label {
      padding-left: 4px;
      color: desktop-variables.$main-white;
      opacity: 0.6;
      cursor: pointer;
    }

    .bottomLine {
      width: 100%;
      height: 4px;
      border-radius: 2px;
      background-color: desktop-variables.$main-gray-2;
    }
  }

  .active {
    .label {
      color: desktop-variables.$main-white;
      opacity: 1;
    }

    .bottomLine {
      background-color: desktop-variables.$main-gray-1;
    }
  }

  .prev {
    .label {
      color: desktop-variables.$main-white;
      opacity: 0.7;
    }

    .bottomLine {
      background-color: desktop-variables.$secondary-yellow;
    }
  }
}
