@use '/src/styles/desktop-variables.scss';

@import '/src/styles/core.scss';

.stockChart {
  @include flex(flex-start, flex-start, row);

  position: relative;

  width: 100%;
  height: 268px;

  .tooltip {
    position: relative;
    padding: 2px 3px 1px 5px;
    width: fit-content;
    background: desktop-variables.$secondary-green;
    border-radius: 4px;
  }

  .rect {
    border-radius: 2px;
  }

  .test {
    height: 100px;
    background: #000;
  }

  .label {
    position: absolute;
    right: 0;

    width: 50px;
    height: 10px;

    background-color: desktop-variables.$secondary-green;
  }

  .customLabel {
    text {
      font-size: 12px;
      line-height: 18px;
      font-family: desktop-variables.$road-ua;
    }
  }

  .targetPriceLabel {
    position: absolute;
    left: 8px;
    @include flex();
    white-space: nowrap;
    font-weight: 400;
    margin-top: 5px;
    font-size: 12px;
    line-height: 18px;
    width: 100px;
    height: 18px;
    fill: #f5f5f5;
    padding: 5px;
  }
}
