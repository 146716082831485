@use '/src/styles/variables.scss';
@import '/src/styles/core.scss';

.wrapper {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  padding: 0.25rem 0.75rem 0.75rem 1.25rem;

  .leftControl {
    @include flex(center, flex-start);
    column-gap: 0.5rem;
  }

  .rightControlWrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    column-gap: 12px;

    .icon {
      @include size(24px, 24px);
    }
  }
}
