@import '/src/styles/core.scss';

.settingsConnection {
  @include flex(flex-start, flex-start, column);
  row-gap: 2rem;
  width: 100%;

  .button {
    width: 100%;
  }
}
