@use '/src/styles/variables.scss';
@import '/src/styles/core.scss';

.wrapper {
  @include flex(flex-start, flex-start, column);
  padding: 1.25rem 1.25rem 2.5rem 1.25rem;
  row-gap: 2rem;
  background: variables.$main-dark-gray-2;
  width: 100%;

  .chartBlock {
    @include flex(flex-start, flex-start, column);
    width: 100%;

    .tabMenu {
      margin-bottom: 0.75rem;
    }

    .chartTitle {
      color: variables.$main-gray-1;
    }

    > :last-child {
      width: calc(100% + 10px);
      margin: 0 -0.3125rem;
    }
  }

  .description {
    @include flex(flex-start, space-between);
    column-gap: 1rem;
    width: 100%;

    .col {
      @include flex(flex-start, flex-start, column);
      width: fit-content;
      row-gap: 1.75rem;

      .labelHeader {
        text-transform: uppercase;
      }

      .innerCol {
        @include flex(flex-start, flex-start, column);
        width: fit-content;
        row-gap: 0.5rem;

        .label {
          opacity: 0.7;
        }
      }
    }
  }

  .accordion {
    border-radius: 0.125rem;

    .indicators {
      @include flex(flex-start, center, column);
      row-gap: 1rem;

      &:not(&--last) {
        padding-bottom: 1rem;
        border-bottom: 1px solid variables.$main-gray-4;
      }

      & > div {
        @include flex(flex-start, space-between, column);
        row-gap: 0.75rem;
        width: 100%;

        & > div {
          @include flex(center, space-between);
          width: 100%;

          & > div:first-child {
            opacity: 0.7;
          }
        }
      }
    }
  }
}
