@import '/src/styles/core.scss';

.h0 {
  font-size: 72px;
  font-style: normal;
  font-weight: 500;
  line-height: 90%; /* 64.8px */

  @include laptop-s() {
    font-size: 72px;
    font-style: normal;
    font-weight: 500;
    line-height: 90%; /* 64.8px */
  }
}

.h1 {
  font-size: 56px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%; /* 67.2px */

  @include laptop-s() {
    font-size: 48px;
    font-style: normal;
    font-weight: 500;
    line-height: 120%; /* 57.6px */
    letter-spacing: 0.48px;
  }
}

.h2 {
  font-size: 36px;
  font-style: normal;
  font-weight: 700;
  line-height: 120%; /* 43.2px */

  @include laptop-s() {
    font-size: 40px;
    font-style: normal;
    font-weight: 500;
    line-height: 120%; /* 48px */
  }
}

.h3 {
  font-size: 28px;
  font-style: normal;
  font-weight: 500;
  line-height: 120%;

  @include laptop-s() {
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
    line-height: 120%; /* 38.4px */
  }
}

.h4 {
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 120%;

  @include laptop-s() {
    font-size: 32px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%; /* 38.4px */
  }
}

.h5 {
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 120%;

  @include laptop-s() {
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 120%; /* 28.8px */
  }
}

.h6 {
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 120%;

  @include laptop-s() {
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: 120%; /* 28.8px */
  }
}

.h7 {
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;

  @include laptop-s() {
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 120%; /* 24px */
  }
}

.h8 {
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 120%;
}

.bodyText1 {
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;

  @include laptop-s() {
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%; /* 36px */
  }
}

.bodyText2 {
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;

  @include laptop-s() {
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%; /* 27px */
  }
}

.bodyText3 {
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;

  @include laptop-s() {
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%; /* 24px */
  }
}

.bodyText4 {
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%; /* 21px */

  @include laptop-s() {
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%; /* 21px */
  }
}

.bodyText5 {
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 21px */

  @include laptop-s() {
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%; /* 21px */
  }
}

.linkButton {
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 21px */
  text-decoration-line: underline;
  cursor: pointer;
}

.linkButton1 {
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%; /* 27px */
  text-decoration-line: underline;

  @include laptop-s() {
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%; /* 27px */
    text-decoration-line: underline;
  }
}

.linkButton2 {
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 120%; /* 19.2px */
  text-decoration-line: underline;

  @include laptop-s() {
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 120%; /* 19.2px */
    text-decoration-line: underline;
  }
}

.label1 {
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%; /* 19.2px */
  text-transform: uppercase;

  @include laptop-s() {
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 120%; /* 19.2px */
    text-transform: uppercase;
  }
}

.label2 {
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 24px */

  @include laptop-s() {
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 120%; /* 16.8px */
    text-transform: uppercase;
  }
}

.label3 {
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 120%; /* 16.8px */
  text-transform: uppercase;

  @include laptop-s() {
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%; /* 14.4px */
    text-transform: uppercase;
  }
}

.label4 {
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 18px */
  text-transform: uppercase;

  @include laptop-s() {
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%; /* 18px */
    text-transform: none;
  }
}

.label5 {
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 18px */
}

.label6 {
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%; /* 18px */
}

.caption {
  font-size: 11px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%; /* 13.2px */
}

.custom {
  font-size: 27px;
  font-weight: 500;
  font-style: normal;
  line-height: 120%;
}

.custom2 {
  font-size: 32px;
  font-weight: 500;
  font-style: normal;
  line-height: 120%;
}

.custom3 {
  font-size: 18px;
  font-weight: 700;
  font-style: normal;
  line-height: 150%;
}
