@use '/src/styles/desktop-variables.scss';
@import '/src/styles/core.scss';

.wrapper {
  @include flex(flex-start, flex-start);
  width: 100%;
  cursor: grab;
}

.top {
  position: relative;
  padding-top: 1rem;
  margin-top: -1rem;
  transition: all 0.1s;

  &:before {
    position: absolute;
    display: block;
    width: 100%;
    height: 4px;
    content: '';
    top: 0;
    background-color: desktop-variables.$main-gray-2;
    border-radius: 1px;
    overflow: hidden;
    transition: all 0.1s;
  }
}

.bottom {
  position: relative;
  padding-bottom: 1rem;
  margin-bottom: -1rem;
  transition: all 0.1s;

  &:after {
    position: absolute;
    display: block;
    width: 100%;
    height: 4px;
    content: '';
    bottom: 0;
    background-color: desktop-variables.$main-gray-2;
    border-radius: 1px;
    overflow: hidden;
    transition: all 0.1s;
  }
}

.dragging,
.dragging * {
  background-color: #212127;
  filter: none !important;
  backdrop-filter: none !important;
  -webkit-filter: none !important;
  -webkit-backdrop-filter: none !important;
  box-shadow: none !important;
  cursor: grabbing;
}

.dragging {
  position: fixed;
  left: -9999px;
  opacity: 0.7;
  border-radius: 0.375rem;
  border-color: #4b4b57;
  overflow: hidden;
  box-shadow:
    2px 8px 10px 0px rgba(55, 55, 62, 0.17),
    0px 8px 10px 0px rgba(33, 33, 39, 0.2),
    6px 4px 10px 0px rgba(33, 33, 39, 0.24);
}

.hidden {
  opacity: 0;
  width: 0px;
  height: 0px;
}
