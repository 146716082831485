@use '/src/styles/desktop-variables.scss';

@import '/src/styles/core.scss';

.wrapper {
  position: relative;
  @include flex(flex-start, flex-start, column);
  width: 100%;
  color: desktop-variables.$main-light-gray;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
  max-height: 100%;
  overflow: hidden;
  overflow-y: auto;

  > div:last-child > div:last-child {
    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px;
    margin-bottom: 1.25rem;
  }

  .titleRow {
    position: sticky;
    top: 0;
    z-index: 2;
    @include flex(center, space-between);
    column-gap: 1rem;
    width: 100%;
    padding: 1.5rem;
    background-color: desktop-variables.$main-dark-gray-2;

    .ctaButton {
      @include flex(center, center);
      width: fit-content;
      // max-width: 40px;
      height: 100%;
      max-height: 40px;
      border-radius: 4px;
      background-color: desktop-variables.$main-dark-gray-1;
      padding: 8px;
      transition: all 0.2s;

      &:hover {
        transition: all 0.2s;
        cursor: pointer;
        background-color: desktop-variables.$main-gray-2;
        border-color: desktop-variables.$main-white;
      }

      &:focus,
      &:focus-within {
        background-color: desktop-variables.$main-background;
        border-color: desktop-variables.$main-white;
        color: desktop-variables.$main-white;
        transition: all 0.2s;
      }
    }
  }

  .group {
    @include flex(flex-start, flex-start, column);
    width: 100%;

    .label {
      @include flex(center, flex-start);
      width: 100%;
      background-color: desktop-variables.$main-dark-gray-1;
      padding: 1.25rem 1.5rem;
      text-transform: uppercase;
    }
  }
}
