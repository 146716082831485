@use '/src/styles/variables.scss';
@import '/src/styles/core.scss';

.wrapper {
  @include flex(center, flex-start, column);
  width: 100%;
  text-align: center;
  margin-top: 8rem;

  > div {
    margin-top: 1rem;
    opacity: 0.9;

    &:last-child {
      margin-top: 0.75rem;
      opacity: 0.8;
    }
  }
}
