@use '/src/styles/desktop-variables.scss';

@import '/src/styles/core.scss';

.noDesktop {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  height: 100vh;
  min-height: 100vh;
  width: 100%;
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  background-color: desktop-variables.$main-background;
  text-align: center;

  color: desktop-variables.$main-white;
  row-gap: 20px;
  column-gap: 20px;

  .icon {
    max-width: 200px;
    max-height: 100px;
  }
}
