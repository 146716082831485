@use '/src/styles/desktop-variables.scss';

@import '/src/styles/core.scss';

.wrapper {
  @include flex(flex-start, flex-start, column);
  row-gap: 1rem;
  width: 100%;
  padding: 20px;
  background-color: desktop-variables.$main-dark-gray-2;

  &:not(:last-child) {
    border-bottom: 1px solid desktop-variables.$main-dark-gray-1;
  }

  .row {
    @include flex(center, space-between);
    column-gap: 1rem;
    width: 100%;
    color: desktop-variables.$main-light-gray;
  }

  .dateRow {
    opacity: 0.5;
  }

  .infoRows {
    @include flex(flex-start, flex-start, column);
    row-gap: 0.75rem;
    width: 100%;

    .secondaryRow {
      opacity: 0.7;
    }

    .additionalRow {
      opacity: 0.5;
    }
  }
}
