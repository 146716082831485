@use '/src/styles/variables.scss';

@import '/src/styles/core.scss';

.modal {
  overflow: hidden;
  position: fixed;
  z-index: variables.$z-modal;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  @include flex(center, center, column);

  width: 335px;
  padding: 1.25rem;
  border-radius: 2px;
  background-color: variables.$main-background;
  animation: appear 0.1s cubic-bezier(0.5, 0, 1, 1);

  .wrapper {
    @include flex(center, center, column);
    position: relative;
    width: 100%;
    row-gap: 20px;

    .closeIcon {
      position: absolute;
      right: 0;
      top: 0;
      cursor: pointer;
    }

    .contentWrapper {
      @include flex(center, center, column);
      width: 100%;
      row-gap: 24px;
      text-align: center;

      .content {
        @include flex(center, center, column);
        row-gap: 12px;

        .contentBox {
          @include flex(center, flex-start, column);
          flex-direction: column;
          width: 100%;
        }
      }

      .controlButtons {
        @include flex(center, center, column);
        width: 100%;
        row-gap: 20px;

        > button {
          width: 100%;
        }
      }
    }
  }
}

.textCenter {
  text-align: center;
}

@keyframes appear {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
