@use '/src/styles/desktop-variables.scss';
@import '/src/styles/core.scss';

.wrapper {
  position: relative;
  @include flex(flex-start, flex-start, column);
  row-gap: 2.5rem;
  width: 100%;

  .orderAccordion {
    div[role='button'] {
      position: relative;

      svg {
        position: absolute;
        top: 38px;
        right: 7px;
      }
    }
  }

  .info {
    @include flex(flex-start, flex-start, column);
    row-gap: 1.5rem;
    width: 100%;

    .title {
      @include flex(flex-start, flex-start, column);
      row-gap: 0.75rem;
      width: 100%;

      .account {
        opacity: 0.7;
      }

      .orderInfo {
        @include flex(flex-start, flex-start, column);
        row-gap: 1rem;
        width: 100%;

        .contractsList {
          @include flex(flex-start, flex-start, column);
          row-gap: 0.75rem;
          width: 100%;
          opacity: 0.5;

          .contract {
            display: grid;
            width: 100%;
            grid-template-columns: repeat(3, minmax(max-content, 1fr));
            justify-content: flex-start;

            & > div:last-child {
              text-align: right;
            }

            .side {
              text-transform: uppercase;
            }
          }
        }
      }
    }

    .bodyItem {
      @include flex(flex-start, flex-start, column);
      row-gap: 0.75rem;
      width: 100%;

      .itemRow {
        @include flex(center, space-between);
        column-gap: 1rem;
        width: 100%;

        .label {
          opacity: 0.7;
        }
      }
    }

    .combo {
      padding-bottom: 1.5rem;
      border-bottom: 1px solid desktop-variables.$main-gray-3;
    }
  }

  .controls {
    position: sticky;
    bottom: -1px;
    @include flex(center, center);
    column-gap: 1.25rem;
    width: 100%;
    padding-top: 2.5rem;
    margin-top: -2.5rem;
    z-index: 1;
    background-color: desktop-variables.$main-background;

    button {
      width: calc(50% - 0.625rem);
    }
  }
}
