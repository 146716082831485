@use '/src/styles/desktop-variables.scss';

@import '/src/styles/core.scss';

.wrapper {
  @include flex(center, flex-start);
  width: 100%;

  .item {
    @include flex(center, space-between);
    width: 100%;
    column-gap: 0.5rem;
    padding: 0.5rem;
    border-radius: 0.375rem;
    background-color: transparent;
    border: unset;
    outline: unset;
    text-decoration: none;
    white-space: nowrap;

    &:hover {
      cursor: pointer;
      transition: all 0.2s;
      background-color: rgba(40, 40, 46, 0.5);
    }

    &:focus {
      transition: all 0.2s;
      outline: unset;
    }

    //   TODO: update disabled state properties, according to the UI-Kit design.
    &:disabled {
      transition: all 0.2s;
      opacity: 0.5;
    }

    &:disabled:hover {
      cursor: not-allowed;
      background-color: inherit;
    }

    &:visited {
      color: unset;
    }

    .content {
      @include flex(center, flex-start);
      width: fit-content;
      column-gap: 0.5rem;

      .icon,
      .label {
        @include flex();
        color: desktop-variables.$main-white;

        svg {
          * {
            fill: desktop-variables.$main-white;
          }
        }
      }

      .icon {
        width: 1rem;
        height: 1rem;
      }

      .label {
        font-weight: 400;
        font-size: 0.875rem;
        line-height: 1.5rem;
      }
    }
  }

  .active {
    transition: all 0.2s;
    background-color: desktop-variables.$main-dark-gray-1;
  }
}
