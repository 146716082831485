@use '/src/styles/variables.scss';

@import '/src/styles/core.scss';

.monthlyCalendar {
  @include flex(flex-start, flex-start, column);
  width: 100%;
  overflow: hidden;
  overflow-y: auto;

  .content {
    @include flex(flex-start, flex-start, column);
    padding: 0 20px;
    row-gap: 1.25rem;
    width: 100%;
  }

  .yearWrapper {
    @include flex(flex-start, flex-start, column);
    row-gap: 20px;
    margin-top: 20px;
    width: 100%;

    .months {
      @include flex(flex-start, space-between);
      flex-wrap: wrap;
      row-gap: 20px;
      width: 100%;

      .button {
        width: calc(33.33% - 2rem);
      }
    }
  }
}
