@import '../../styles/core.scss';

.footer {
  width: 100%;
  color: var(--primary-text);
  padding: 0 270px;
  background-image: url('../../../../../assets/images/reports/footer/bg.png');
  background-repeat: no-repeat;
  background-position-x: right;
  background-position-y: bottom;

  @include flex(center, center, column);

  & > div {
    margin-top: 56px;
    margin-bottom: 80px;
  }

  & > div:first-child a {
    color: var(--accent-blue);
  }

  &Info {
    width: inherit;
    margin-top: 70px;

    @include flex(center, space-between, row);

    .company {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
      row-gap: 0.3125rem;
      width: fit-content;

      img {
        max-width: 120px;
        height: auto;
        object-fit: contain;
      }
    }

    &Padding {
      padding-bottom: 16px;
    }
  }
}
