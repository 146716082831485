@use '/src/styles/desktop-variables.scss';

@import '/src/styles/core.scss';

.pagination {
  width: 100%;
  display: flex;
  align-items: center;
  column-gap: 8px;

  .arrowRight,
  .arrowLeft,
  .button,
  .selectedButton {
    transition: all 0.2s;
    height: 36px;
    border: none;
    background: desktop-variables.$main-dark-gray-2;
    border-radius: 4px;
    min-width: 36px;
    cursor: pointer;

    &:hover {
      background-color: desktop-variables.$main-dark-gray-1;
    }
  }

  .arrowLeft {
    margin-right: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .arrowRight {
    margin-left: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .selectedButton {
    background: desktop-variables.$main-gray-3;
  }

  .disabled {
    transition: all 0.2s;
    opacity: 0.5;
    background-color: desktop-variables.$main-dark-gray-2;
    cursor: not-allowed;

    &:hover {
      background-color: desktop-variables.$main-dark-gray-2;
    }
  }
}
