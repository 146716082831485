@use '/src/styles/desktop-variables.scss';
@import '/src/styles/core.scss';

.wrapper {
  position: relative;
  @include flex(flex-start, flex-start, column);
  min-width: 100%;
  width: 100%;
  min-height: 100%;
  max-height: 100%;
  overflow: hidden;
  border-radius: 0.375rem;
  padding: 1.25rem;
  padding-top: 0;
  row-gap: 1.25rem;
  background-color: desktop-variables.$main-dark-gray-2;
  transition: all 0.3s;

  > div:first-child {
    position: sticky;
    top: 0;
    left: 0;
    z-index: 1;
    width: calc(100% + 2.5rem);
    margin: 0 -1.25rem -1.25rem -1.25rem;
    padding: 1.25rem;
    background-color: desktop-variables.$main-dark-gray-2;
    transition: all 0.3s;
  }

  .cardsList {
    @include flex(flex-start, flex-start, column);
    min-width: 100%;
    width: 100%;
    min-height: 100%;
    min-height: calc(100% - 64px);

    max-height: calc(100% - 64px);
    overflow: auto;
    row-gap: 1rem;
  }
}

.targetColumn {
  background-color: #2c2c32;
  transition: all 0.3s;

  > div:first-child {
    background-color: #2c2c32;
    transition: all 0.3s;
  }
}

.addSkeleton .cardsList::after {
  display: block;
  width: calc(100% - 2px);
  height: 170px;
  content: '';
  font-size: 1.5rem;
  font-weight: lighter;
  line-height: 7.8125rem;
  text-align: center;
  background-image: url('../../../../../assets/images/kanban/plus.svg');
  background-position: center;
  // background-size: 100% auto;
  background-repeat: no-repeat;
  color: desktop-variables.$main-gray-2;
  border: 1px solid transparent;
  border-image-source: url('../../../../../assets/images/kanban/border.svg');
  border-image-width: 1;
  border-image-slice: 36;
  border-image-repeat: round;
  border-radius: 0.375rem;
  transition: all 0.3s;
}
