@use '/src/styles/variables.scss';

@import '/src/styles/core.scss';

.overlay {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100vw;
  position: fixed;
  left: 0;
  top: 0;
  z-index: variables.$z-preloader;
  background-color: rgba(variables.$main-background, 0.6);
}

.preloader {
  @include flex();
  @include size(2.5rem, 2.5rem);
  animation: 0.9s linear infinite small-rotation;
}

.small {
  @include flex();
  @include size(24px, 24px);
  animation: 0.9s linear infinite small-rotation;
}

@keyframes small-rotation {
  0% {
    transform: rotateZ(0deg);
  }

  100% {
    transform: rotateZ(359deg);
  }
}

@keyframes lds-spinner {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}
