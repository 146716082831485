@use '/src/styles/variables.scss';

@import '/src/styles/core.scss';

.badge {
  display: flex;
  align-items: center;
  padding: 8px 12px;
  column-gap: 4px;
  max-width: fit-content;
  transition: all 0.2s;
  border-radius: 20px;
  border: 1px solid variables.$main-gray-2;

  .icon {
    @include size(20px, 20px);
  }

  &:focus {
    border: 1px solid variables.$secondary-yellow;
    outline: none;
    transition: all 0.2s;
  }
}
