@use '/src/styles/desktop-variables.scss';

@import '/src/styles/core.scss';

.accordionBody {
  @include flex(flex-start, flex-start, column);
  gap: 20px;
  width: 100%;

  .row {
    @include flex(center, space-between);
    width: 100%;

    .flex {
      @include flex(center, space-between);
      width: 100%;
    }

    .text {
      width: fit-content;
      color: desktop-variables.$main-light-gray;
      opacity: 0.8;
      width: 100%;
    }

    svg {
      width: 24px;
      height: 24px;

      * {
        fill: desktop-variables.$main-light-gray;
      }
    }
  }

  .row:first-child {
    margin-top: 1.25rem;
  }

  .statusIcon {
    svg {
      width: 12px;
      height: 12px;
    }

    .cancel {
      * {
        fill: desktop-variables.$secondary-red;
      }
    }

    .approve {
      * {
        fill: desktop-variables.$secondary-green;
      }
    }
  }
}
