@use '/src/styles/desktop-variables.scss';

@import '/src/styles/core.scss';

.wrapper {
  @include flex(flex-start, flex-start, column);
  row-gap: 20px;
  width: 100%;

  .form {
    @include flex(flex-start, flex-start, column);
    row-gap: 20px;
    margin-bottom: 20px;
    width: 100%;

    .leadingIcon {
      @include fill(desktop-variables.$main-gray-1);

      &:hover {
        cursor: pointer;
        @include fill(desktop-variables.$main-white);
      }
    }

    .chevron {
      width: 1.5rem;
      height: 1.5rem;
    }
  }

  .controls {
    position: sticky;
    bottom: 0;
    margin-top: -2.5rem;
    padding-top: 2.5rem;
    @include flex();
    width: 100%;
    background-color: desktop-variables.$main-background;

    button {
      width: 100%;
    }
  }
}
