@use '/src/styles/desktop-variables.scss';

@import '/src/styles/core.scss';

.wrapper {
  display: grid;
  width: 100%;
  max-height: 100%;
  overflow: hidden;
  background-color: desktop-variables.$main-dark-gray-2;
  border-radius: 6px;

  .table {
    position: relative;
    display: grid;
    width: 100%;
    border-spacing: 0px;
    height: fit-content;
    max-height: 100%;
    max-width: 100%;
    overflow: auto;
    color: desktop-variables.$main-white;

    > .tr:not(:first-child) {
      transition: all 0.2s;

      &:hover {
        transition: all 0.2s;
        background-color: #2c2c32;
      }
    }

    .heading {
      position: sticky;
      top: 0;
      z-index: 2;
      background-color: desktop-variables.$main-dark-gray-2;

      .tr:nth-child(even) {
        background-color: desktop-variables.$main-dark-gray-2;
      }

      .tr:nth-child(odd) {
        background-color: desktop-variables.$main-dark-gray-2;
      }
    }

    .sectionName {
      display: grid;
      width: 100%;
      padding: 1.5rem;
      border-bottom: 1px solid desktop-variables.$main-gray-3;
      text-transform: uppercase;
    }

    .tr {
      display: grid;
      width: 100%;
      min-width: min-content;
      align-items: center;
      line-height: unset;

      &.selectedRow {
        border-top: 1px solid rgba($color: desktop-variables.$secondary-yellow, $alpha: 0.35);
        border-bottom: 1px solid rgba($color: desktop-variables.$secondary-yellow, $alpha: 0.35);
        background-color: desktop-variables.$main-background !important;
      }

      .th,
      .td {
        display: grid;
        grid-auto-flow: column;
        box-sizing: border-box;
        justify-content: flex-start;
        align-items: center;
        width: 100%;
        max-width: 100%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        grid-auto-columns: auto;
        grid-auto-flow: column;
        column-gap: 4px;
        justify-content: flex-start;

        * {
          max-width: 100%;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }

      .th {
        padding: 1.5625rem 1rem;
        font-size: 0.75rem;
        line-height: 0.875rem;
        font-weight: 400;
        text-transform: uppercase;

        .chevronDown {
          transition: all 0.3s;

          &:hover {
            cursor: pointer;
          }

          * {
            fill: desktop-variables.$main-white;
          }
        }

        .chevronUp {
          transform: rotateX(180deg);
          transition: all 0.3s;
        }
      }

      .thHeading {
        display: grid;
        grid-auto-flow: column;
        box-sizing: border-box;
        align-items: center;
        column-gap: 4px;
        cursor: pointer;
      }

      .hiddenTh {
        visibility: hidden;
      }

      .td {
        padding: 1.0938rem 1rem;
        font-size: 0.875rem;
        line-height: 1.3125rem;
        font-weight: 400;
      }

      .preloader {
        justify-content: center;
      }

      .cta {
        position: sticky;
        right: 0;
        justify-self: flex-end;
        justify-content: flex-end;
        background-color: inherit;
        z-index: 1;
        min-width: fit-content;
        width: fit-content;
        height: 100%;
        padding-top: 8px;
        padding-left: 0;
        padding-bottom: 8px;
        padding-right: 1.25rem;

        > * {
          max-width: unset;
          white-space: unset;
          overflow: unset;
          text-overflow: unset;
          min-width: 24px;
          width: 100%;

          &:hover {
            cursor: pointer;
          }
        }
      }

      .checkbox {
        padding: 1rem 0.5rem 1rem 1.25rem;
      }
    }

    .tr:nth-child(even) {
      background-color: desktop-variables.$main-dark-gray-1;
    }

    .tr:nth-child(odd) {
      background-color: desktop-variables.$main-dark-gray-2;
    }

    &:not(:last-child) {
      border-bottom: 1px solid desktop-variables.$main-gray-3;
    }
  }
}

.tooltip {
  position: absolute;
  z-index: 10;
  display: block;
  min-width: fit-content;
  height: fit-content;
  background-color: desktop-variables.$main-gray-3;
  color: desktop-variables.$main-white;
  overflow: visible;
  text-overflow: initial;
  white-space: nowrap;
  padding: 0.5rem 1rem;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  border-radius: 0.375rem;
}

.triangle {
  position: absolute;
  z-index: 10;
  display: block;
  width: 14px;
  height: 6px;
  background-color: desktop-variables.$main-gray-3;
  clip-path: polygon(50% 0%, 0% 100%, 100% 100%);
  border-radius: 100% 100% 0 0;
}
