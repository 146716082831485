.table {
  display: table;
  width: 100%;
  border-collapse: collapse;
  border: 1px solid var(--border-dark);
  background-color: var(--white);
  color: var(--dark-1);

  & tr {
    border-bottom: 1px solid var(--border-dark);
  }

  & thead tr {
    background-color: var(--light-blue);
  }
}
