@use '/src/styles/desktop-variables.scss';
@import '/src/styles/core.scss';

.progressBar {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 20px;
  background-color: desktop-variables.$main-background;
  border-radius: 4px;
  margin: 8px 0;
  position: relative;

  .grow {
    padding-top: 2px;
    animation-duration: 1s;
    animation-name: slidein;
    border-radius: 4px;

    height: 100%;
    background-color: desktop-variables.$secondary-yellow;
  }

  .percentage {
    @include flex(center, center);
    height: 100%;
    position: absolute;
    right: 0;
    left: 0;
    margin: auto;
    opacity: 0.5;

    animation-duration: 1.2s;
    animation-name: appear;
  }

  .percentageDark {
    color: desktop-variables.$main-background;
    opacity: 1;
  }

  .disabled {
    display: none;
  }
}

@keyframes slidein {
  from {
    width: 0;
  }
}

@keyframes appear {
  from {
    opacity: 0;
  }
  50% {
    opacity: 0;
  }
}
