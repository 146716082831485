@import '../../styles/core.scss';

.header {
  background-color: $black;
  color: $white;
  padding: 0 58px 0 150px;
  position: fixed;
  width: 100%;
  z-index: 5;
  top: 0;
  left: 0;

  @include flex(center, space-between, row);

  .headerInfo {
    @include flex(center, center, row);

    p {
      padding: 32px;
      margin: 0;
      position: relative;
    }

    & > p:nth-child(2)::after {
      content: '';
      width: 1px;
      height: 18px;
      background-color: #636a73;
      display: inline-block;
      right: 5px;
      top: calc(50% - 9px);
      position: absolute;
    }

    & > p:nth-child(2)::before {
      content: '';
      width: 1px;
      height: 18px;
      background-color: #636a73;
      display: inline-block;
      left: 5px;
      top: calc(50% - 9px);
      position: absolute;
    }
  }

  .headerAction {
    @include flex(center, center, row);
    button {
      color: #fff;
    }

    .downloadButton {
      height: 45px;
      width: 45px;
      padding: 0;
      justify-content: center;
      align-items: center;
    }
  }

  .headerPadding {
    margin-left: 24px;
  }
}
