@import '/src/styles/core.scss';

.overlay {
  width: 100%;
  min-height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 5;
  overflow: hidden;

  @include tablet() {
    backdrop-filter: blur(10px);
    background: rgba(75, 75, 87, 0.6);
  }

  &-close {
    position: fixed;
    top: 0;
    padding: 30px 16px;
    z-index: 100;

    @include tablet() {
      display: none;
    }

    &--invisible {
      display: none;
    }
  }

  &--pattern-bg {
    background-size: cover;
  }
}

.full {
  width: 100%;
}

.enter {
  opacity: 0;
}
.enter-active {
  opacity: 1;
  transition: opacity 0.3s;
}
.exit {
  opacity: 1;
}
.exit-active {
  opacity: 0;
  transition: opacity 0.3s;
}

.transparent {
  backdrop-filter: blur(10px);
}
