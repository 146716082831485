@use '/src/styles/variables.scss';
@import '/src/styles/mixins.scss';

.wrapper {
  @include flex(center, center);
  column-gap: 2px;
  width: fit-content;
  font-size: 0.75rem;
  font-weight: 400;
  line-height: 150%;

  .icon {
    &.positive {
      transform: rotate(180deg);
      @include fill(variables.$secondary-green-1);
    }

    &.negative {
      @include fill(variables.$secondary-red-1);
    }
  }

  .positive {
    color: variables.$secondary-green-1;
  }

  .negative {
    color: variables.$secondary-red-1;
  }
}
