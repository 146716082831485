@mixin flex($align: center, $justify: center, $direction: row) {
  display: flex;
  justify-content: $justify;
  align-items: $align;
  flex-direction: $direction;
}

@mixin grid($align: center, $justify: center) {
  display: grid;
  align-items: $align;
  justify-content: $justify;
}

@mixin fill($color) {
  svg {
    fill: $color;
  }
  g {
    fill: $color;
  }
  path {
    fill: $color;
  }
}

@mixin stroke($color) {
  svg {
    stroke: $color;
  }
  g {
    stroke: $color;
  }
  path {
    stroke: $color;
  }
}

@mixin truncate($width: 100px) {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: $width;
}

@mixin size($width, $height) {
  width: $width;
  height: $height;
}

@mixin appear($transition-duration: 0.8s) {
  animation-duration: $transition-duration;
  animation-name: opacity-transition;
  transition: ease-in-out;
}

@keyframes opacity-transition {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}
