@use '/src/styles/desktop-variables.scss';

@import '/src/styles/core.scss';

.modal {
  position: fixed;
  left: 50%;
  top: 50%;
  // z-index: 25;
  @include flex(flex-start, flex-start, column);
  transform: translate(-50%, -50%);
  background-color: desktop-variables.$main-background;
  color: desktop-variables.$main-white;
  width: fit-content;
  min-width: 508px;
  max-width: 508px;
  max-height: calc(100vh - 120px - 2rem);
  overflow: hidden;
  overflow-y: auto;

  padding: 2rem 1rem 2rem 2rem;
  border-radius: 6px;

  .contentWrapper {
    @include flex(flex-start, flex-start, column);
    row-gap: 1.25rem;
    width: 100%;
  }

  .titleRow {
    @include flex(center, space-between);
    column-gap: 1rem;
    width: 100%;
    max-width: 444px;

    .closeButton:hover {
      cursor: pointer;
    }

    .title {
      @include flex(center, flex-start);
      column-gap: 0.75rem;
      width: fit-content;

      .backButton:hover {
        cursor: pointer;
      }
    }
  }

  .content {
    @include flex(flex-start, flex-start);
    width: 100%;
    max-height: calc(100vh - 120px - 2rem - 48.8px - 4rem);
    overflow-x: visible;
    overflow-y: auto;

    &::-webkit-scrollbar,
    &::-webkit-scrollbar-track,
    &::-webkit-scrollbar-thumb {
      display: block;
      width: 5px;
    }

    &::-webkit-scrollbar-thumb {
      display: block;

      background: #4b4b57;
      border-radius: 4px;
    }

    &::-webkit-scrollbar-track {
      display: block;
      background: #202025;
      border-radius: 4px;
    }

    > * {
      max-width: 444px;
    }
  }
}
