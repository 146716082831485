@use '/src/styles/desktop-variables.scss';

@import '/src/styles/core.scss';

.wrapper {
  @include flex(flex-start, flex-start, column);
  width: 100%;
  row-gap: 24px;

  .table {
    display: grid;
    grid-template-columns: calc(100% - 144px) 144px;
    justify-content: space-between;
    width: 100%;

    .col {
      display: grid;
      grid-template-columns: 100%;
      grid-auto-flow: row;
      justify-content: flex-start;
      align-items: center;
      row-gap: 12px;
      width: 100%;
      max-width: 100%;

      .label {
        display: grid;
        width: 100%;
        padding-bottom: 12px;
        border-bottom: 1px solid desktop-variables.$main-dark-gray-1;

        &:last-child {
          border-bottom: none;
        }
      }

      .valueWrapper {
        display: grid;
        grid-auto-flow: column;
        justify-content: flex-start;
        align-items: center;
        column-gap: 4px;
        width: 100%;
        max-width: 100%;
        overflow: hidden;
        padding-bottom: 12px;
        border-bottom: 1px solid desktop-variables.$main-dark-gray-1;

        &:last-child {
          border-bottom: none;
        }

        .icon {
          min-width: 20px;
          min-height: 20px;
          max-width: 20px;
          max-height: 20px;

          &.checkIcon {
            @include fill(desktop-variables.$secondary-green);
          }

          &.crossIcon {
            @include fill(desktop-variables.$secondary-red);
          }
        }

        .value {
          max-width: 100%;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }
      }
    }
  }
}
