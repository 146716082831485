@use '/src/styles/desktop-variables.scss';
@import '/src/styles/core.scss';

.smallLineChart {
  width: 50px;
  margin-left: 16px;

  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  text-align: right;

  .contrast {
    color: desktop-variables.$main-gray-1;
  }
}
