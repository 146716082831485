@use '/src/styles/desktop-variables.scss';
@import '/src/styles/core.scss';

.content {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 28px 32px;
  border-radius: 6px;
  color: desktop-variables.$main-light-gray;
  outline: none;

  .chartData {
    @include flex(center, flex-start, row);
    margin-bottom: 11px;
    color: desktop-variables.$main-white;
    opacity: 0.8;

    .chartColor {
      width: 10px;
      height: 10px;
      border-radius: 2px;
      margin-right: 8px;
    }

    .name {
      @include flex(center, flex-start);
      line-height: 0.9rem;
      text-transform: uppercase;
    }
  }

  .chart {
    margin-top: 27px;
    height: 60px;
  }

  .subtitle {
    @include flex(center, flex-start, row);
    color: desktop-variables.$main-white;

    .logoWrapper {
      width: 24px;
      height: 24px;
      border-radius: 50%;
      background-color: desktop-variables.$main-white;
      @include flex(center, center, row);
      margin-right: 12px;
      overflow: hidden;

      img {
        max-width: 90%;
        max-height: 90%;
        object-fit: cover;
      }
    }

    .infoButton {
      margin-left: 14px;
      cursor: pointer;
    }
  }

  .row {
    width: 100%;
    height: auto;
    margin-top: 28px;
    @include flex(center, space-between, row);

    .block {
      @include flex(center, space-between, row);
      width: 100%;
    }

    .label {
      line-height: 0.75rem;
      text-transform: uppercase;
      opacity: 50%;
      margin-bottom: 8px;
      font-weight: 400;
    }
  }

  .hr {
    background: desktop-variables.$main-gray-3;
    border: none;
    height: 1px;
    margin-top: 28px;
  }
}
