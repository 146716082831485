@use '/src/styles/desktop-variables.scss';

@import '/src/styles/core.scss';

.paymentChart {
  width: 100%;
  height: 93px;

  display: flex;
  flex-direction: column;

  .container {
    width: 100%;
    height: 93px;
    display: grid;
    position: relative;
  }

  .charts {
    display: flex;
  }
}
