@use '/src/styles/desktop-variables.scss';
@import '/src/styles/core.scss';

.statusBar {
  display: flex;
  flex-direction: row;
  height: 24px;
  background-color: desktop-variables.$secondary-green;
  border-radius: 4px;

  .grow {
    padding-top: 2px;
    animation-duration: 1s;
    animation-name: slidein;
    border-radius: 4px;
    position: relative;

    height: 100%;
    background-color: desktop-variables.$secondary-turquoise;
  }
}

@keyframes slidein {
  from {
    width: 0;
  }
}

@keyframes appear {
  from {
    opacity: 0;
  }
  50% {
    opacity: 0;
  }
}
