@import '../../../styles/core.scss';

.text {
  font-style: normal;
  font-weight: 400;
  color: var(--dark-1);

  &H1 {
    font-weight: 600;
    font-size: 50px;
    line-height: 61px;
  }

  &H2 {
    font-weight: 600;
    font-size: 44px;
    line-height: 61px;
  }

  &H3 {
    font-weight: 500;
    font-size: 32px;
    line-height: 42px;
  }

  &H4 {
    font-weight: 500;
    font-size: 24px;
    line-height: 29px;
  }

  &H5 {
    font-weight: 500;
    font-size: 21px;
    line-height: 26px;
  }

  &H6 {
    font-weight: 500;
    font-size: 18px;
    line-height: 22px;
  }

  &Body {
    font-size: 14px;
    line-height: 17px;
  }

  &Body1 {
    font-size: 15px;
    line-height: 18px;
  }

  &Body3 {
    font-size: 13px;
    line-height: 18px;
  }

  &Fw500 {
    font-weight: 500;
  }

  &Fw600 {
    font-weight: 600;
  }

  &Fw700 {
    font-weight: 700;
  }

  &Small {
    font-size: 12px;
    line-height: 14px;
  }

  &Lh13 {
    line-height: 13px;
  }

  &Lh15 {
    line-height: 15px;
  }

  &Lh18 {
    line-height: 18px;
  }

  &Capitalize {
    text-transform: capitalize;
  }

  &Inverted {
    color: var(--white);
  }

  &Secondary {
    color: var(--secondary-bg-2);
  }

  &AlignLeft {
    text-align: left;
  }

  &AlignRight {
    text-align: right;
  }

  &AlignCenter {
    text-align: center;
  }

  &Uppercase {
    text-transform: uppercase;
  }
}
