$black: #000;
$white: #ffffff;
$primary: #1890ff;
$primary-gray: #333;
$primary-lighter: #3faaff;
$primary-light-gray: #d9d9d9;
$transparent: rgba(0, 0, 0, 0);

// Font families
$montserrat: 'Montserrat', sans-serif;
$work-sans: 'Work Sans', sans-serif;

// App primary colors
$primary-dark: rgb(15, 15, 17);
$primary-light: #f2f3f9;

// App accent colors
$accent-green: #30bb70;
$accent-red: #c91d12;
$accent-yellow: #f1f45e;
$accent-blue: #00a19a;
$light-blue: #f6f8fb;
$orange: #f49d5e;

$transition: 0.2s;

// App secondary accent colors
$accent-green-light: #40f493;
$accent-red-light: #e82518;

// App secondary colors
$primary-dark-1: #28282e;
$primary-dark-2: #4b4b57;
$primary-dark-3: #101030;
$primary-dark-4: #787880;
$primary-dark-5: #c9cbd7;
$primary-dark-6: #202025;
$dark-1: #0f0f11;
$dark-2: #121214;
$border-dark: #e0e0e0;

// Visible area (for line and scroll)
$visible-area-desktop: 72vh;

// Gradients
$gradient-green: linear-gradient(180.07deg, $accent-green 44.79%, rgba(48, 187, 112, 0) 78.53%);
$gradient-light: linear-gradient(180deg, $primary-light -99.11%, rgba(255, 255, 255, 0) 100%);

// Secondary/helper colors
$pale-red: #d73f36;
$sky-blue: #2ea1e2;
$form-dark-1: rgba(40, 40, 46, 0.1);
$primary-underline: rgba(15, 15, 17, 0.3);
$transparent-dark: rgba(15, 15, 17, 0.7);
$table-underline: rgba(75, 75, 87, 0.4);

// SIZES FOR TEXT

// title1
$title1-size: 24px;

// title2
$title2-size: 18px;

// title3
$title3-size: 21px;

// actions
$action-size: 15px;

// body
$normal-size: 14px;

// subtitle
$subtitle-size: 12px;
// ticker
$ticker-size: 10px;

// extra large
$large-size: 50px;

// medium large
$medium-large-size: 32px;

$bold-weight: 700;
$medium-weight: 500;
$normal-weight: 400;

$darkScheme: (
  'primary-bg': $primary-dark,
  'primary-text': $white,
  'secondary-text': $primary-light,
  'secondary-bg': $primary-dark-1,
  'secondary-bg-2': $primary-dark-2,
  'error': $pale-red,
  'primary-input-border': $white,
  'primary-form-bg': $primary-dark-3,
  'black': $black,
  'accent-red': $accent-red,
  'accent-green': $accent-green,
  'dark-1': $dark-1,
  'dark-2': $dark-2,
  'accent-blue': $accent-blue,
  'light-blue': $light-blue,
  'white': $white,
  'border-dark': $border-dark
);

$lightScheme: (
  'primary-bg': $primary-light,
  'primary-text': $black,
  'secondary-text': $primary-dark,
  'accent-blue': $accent-blue,
  'secondary-bg-2': $white,
  'error': $pale-red,
  'primary-input-border': $primary-dark-1,
  'primary-form-bg': $transparent,
  'accent-red': $accent-red,
  'accent-green': $accent-green,
  'dark-1': $dark-1,
  'black': $black,
  'dark-2': $dark-2,
  'light-blue': $light-blue,
  'white': $white,
  'border-dark': $border-dark
);
