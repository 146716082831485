@import '/src/styles/core.scss';

.wrapper {
  display: flex;
  flex-direction: column;
  row-gap: 40px;
  width: 100%;

  .form {
    display: flex;
    flex-direction: column;
    row-gap: 40px;
    width: 100%;
  }

  .periodWrapper {
    display: flex;
    flex-direction: column;
    row-gap: 20px;

    .content {
      display: flex;
      flex-direction: column;
      row-gap: 24px;
      width: 100%;
    }

    .radiosWrapper {
      display: flex;
      column-gap: 16px;
    }
  }

  .objectWrapper {
    display: flex;
    flex-direction: column;
    row-gap: 20px;

    .content {
      display: flex;
      flex-direction: column;
      row-gap: 24px;
      width: 100%;
    }

    .radiosWrapper {
      display: flex;
      column-gap: 16px;
    }
  }

  .controlButtons {
    width: 100%;
    display: flex;
    column-gap: 20px;

    .button {
      width: 100%;
    }
  }
}
