@use '/src/styles/variables.scss';

[class*='Toastify__toast--error'] {
  [class*='Toastify__toast-icon'] {
    width: 16px;
    height: 16px;

    svg {
      width: 16px;
      height: 16px;
      fill: variables.$secondary-red-1;
    }
  }
}

[class*='Toastify__progress-bar--error'] {
  background: variables.$secondary-red-1;
}

[class*='Toastify__toast--success'] {
  [class*='Toastify__toast-icon'] {
    width: 16px;
    height: 16px;

    svg {
      width: 16px;
      height: 16px;
      fill: variables.$secondary-green-1;
    }
  }
}

[class*='Toastify__progress-bar--success'] {
  background: variables.$secondary-green-1;
}

[class*='Toastify__toast--info'] {
  [class*='Toastify__toast-icon'] {
    width: 16px;
    height: 16px;

    svg {
      width: 16px;
      height: 16px;
      fill: variables.$main-light-gray;
    }
  }
}

[class*='Toastify__progress-bar--info'],
[class*='Toastify__progress-bar--default'] {
  background: variables.$main-light-gray;
}

[class*='Toastify__close-button'] {
  width: 14px;
  height: 14px;

  svg {
    width: 14px;
    height: 14px;
  }
}
