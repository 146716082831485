@import '/src/styles/core.scss';

.wrapper {
  @include flex(flex-start, flex-start, column);
  row-gap: 2.5rem;
  width: 100%;

  .content {
    @include flex(flex-start, flex-start, column);
    row-gap: 2rem;
    width: 100%;

    button {
      width: fit-content;
      padding: 0.5rem 0.75rem;
    }
  }

  button {
    width: 100%;
  }
}
