@use '/src/styles/variables.scss';

@import '/src/styles/core.scss';

.tab {
  @include flex(flex-start, flex-start, column);
  row-gap: 0.375rem;
  width: 100%;
  padding-top: 0.125rem;

  .label {
    padding-left: 4px;
    color: variables.$main-white;
    opacity: 0.6;
    cursor: pointer;
  }

  .bottomLine {
    width: 100%;
    height: 4px;
    border-radius: 2px;
    background-color: variables.$main-gray-2;
  }
}

.active {
  .label {
    color: variables.$main-white;
    opacity: 1;
  }

  .bottomLine {
    background-color: variables.$main-gray-1;
  }
}

.prev {
  .label {
    color: variables.$main-white;
    opacity: 1;
  }

  .bottomLine {
    background-color: variables.$secondary-yellow;
  }
}
