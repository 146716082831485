@use '/src/styles/desktop-variables.scss';

@import '/src/styles/core.scss';

.accordionList {
  width: 100%;
  padding: 0px 0px;
  @include flex(flex-start, space-between, column);
  gap: 25px;

  .expanded {
    height: 100%;
  }
}
