@use '/src/styles/variables.scss';

@import '/src/styles/core.scss';

.twoFaInput {
  @include flex(center, center);
  @include size(54px, 54px);
  background-color: variables.$main-dark-gray-1;
  border: unset;
  outline: unset;
  border-radius: 2px;
  text-align: center;
  font-size: 2rem;
  line-height: 120%;
  font-weight: 700;
  color: variables.$main-white;

  @include laptop-s() {
    width: 73.64px;
    height: 72px;
    border-radius: 0.3125rem;
  }

  &.error {
    border: 1px solid variables.$secondary-red-1;
  }

  &:focus {
    outline: unset;
    border: unset;
  }

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  &[type='number'] {
    appearance: textfield;
    -moz-appearance: textfield;
  }

  &::placeholder {
    color: variables.$main-white;
  }
}
