@use '/src/styles/desktop-variables.scss';

@import '/src/styles/core.scss';

.wrapper {
  @include flex(flex-start, flex-start, column);
  row-gap: 2rem;
  max-width: 444px;

  form {
    @include flex(flex-start, flex-start, column);
    row-gap: 1.5rem;
    width: 100%;

    .inputRow {
      @include flex(flex-start, flex-start, column);
      width: 100%;
      row-gap: 8px;

      .inputsWrapper {
        @include flex();
        column-gap: 3.25rem;
      }

      .errorMessage {
        color: desktop-variables.$secondary-red;
      }
    }

    .resendCode {
      @include flex(center, space-between);
      width: 100%;

      > button {
        text-decoration: underline;
      }
    }

    > button {
      width: 100%;
    }
  }
}
