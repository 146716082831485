@use '/src/styles/desktop-variables.scss';

@import '/src/styles/core.scss';

.monthlyCalendar {
  @include flex(flex-start, flex-start, column);
  row-gap: 40px;
  width: 100%;

  .content {
    @include flex(flex-start, flex-start, column);
    row-gap: 1.25rem;
    width: 100%;
  }

  .yearsCalendar {
    @include flex(flex-start, space-evenly, row);
    column-gap: 1.25rem;
    width: 100%;
    max-width: 100%;
  }

  .year {
    @include flex(center, center);
    flex-grow: 1;

    .button {
      @include flex(center, center);
      flex-grow: 1;
    }
  }

  .yearWrapper {
    @include flex(flex-start, flex-start, column);
    row-gap: 20px;
    margin-top: 20px;

    .months {
      @include flex(flex-start, space-between);
      flex-wrap: wrap;
      row-gap: 20px;
      width: 444px;

      .button {
        width: calc(33.33% - 40px);
      }
    }
  }

  .controlButtons {
    position: sticky;
    bottom: 0;
    @include flex(flex-start, space-between);
    column-gap: 20px;
    width: 100%;
    background-color: desktop-variables.$main-background;
    z-index: 1;
    padding-top: 1rem;
    margin-top: -1rem;

    .button {
      width: 100%;
    }
  }
}
