@use '/src/styles/variables.scss';

@import '/src/styles/core.scss';

.button {
  @include flex(center, center, row);
  outline: none;
  position: relative;
  cursor: pointer;
  width: fit-content;
  background-color: transparent;
  border: 1px solid transparent;
  outline: unset;
  transition: all 0.2s;
  column-gap: 8px;

  .icon {
    @include size(24px, 24px);
    @include flex(center, center);
  }

  &:disabled {
    cursor: not-allowed;
  }
}

.disabled {
  &:hover {
    cursor: not-allowed;
  }
}

/* SIZES */

.small {
  padding: 8px;
}

.medium {
  padding: 8px 12px;
}

.floating {
  padding: 16px;

  border-radius: 100px;
  background: variables.$main-gray-4;
  box-shadow:
    -7px -7px 40px 0px rgba(0, 0, 0, 0.25),
    7px 7px 40px 0px rgba(0, 0, 0, 0.25);

  &.active {
    background: variables.$main-gray-2;
  }
}

/* VARIANTS */

.main1 {
  background-color: variables.$main-dark-gray-1;
  color: variables.$main-white;
  border-radius: 4px;

  &.active {
    background-color: variables.$main-background;
    border-color: variables.$main-background;
    color: variables.$linear-red;
  }

  &:disabled {
    background-color: variables.$main-dark-gray-1;
    color: variables.$main-white;
    opacity: 0.5;
  }
}

.secondary {
  background-color: variables.$secondary-brand-color;
  color: variables.$main-white;
  border-radius: 4px;

  &.active {
    background-color: variables.$secondary-dark-green;
    color: variables.$main-white;
  }

  &:disabled {
    background-color: variables.$secondary-brand-color;
    color: variables.$main-white;
    opacity: 0.5;
  }
}

.outline {
  border-color: variables.$main-gray-3;
  color: variables.$main-white;
  border-radius: 4px;

  &:disabled {
    opacity: 0.5;
  }
}

.borderless {
  color: variables.$main-white;
  border-radius: 4px;

  &:disabled {
    opacity: 0.5;
  }
}

.ignoreSVGColor {
  .icon svg,
  svg {
    fill: unset;
    color: unset;
  }
}
