@use '/src/styles/desktop-variables.scss';

@import '/src/styles/core.scss';

.datePickerTab {
  all: unset;
  @include flex(center, center);
  padding: 10px; // TODO: check with designers
  border-radius: 4px;
  background-color: transparent;
  border: 1px solid desktop-variables.$main-gray-1;
  transition: all 0.2s;

  .title {
    transition: all 0.2s;
    color: desktop-variables.$main-white;
  }

  &:hover {
    transition: all 0.2s;
    background: rgba(75, 75, 87, 0.5);
    border: 1px solid desktop-variables.$main-gray-1;
    cursor: pointer;

    .title {
      transition: all 0.2s;
      color: desktop-variables.$main-white;
    }
  }

  &.selected {
    transition: all 0.2s;
    background-color: desktop-variables.$main-gray-3;
    border: 1px solid desktop-variables.$main-gray-1;

    .title {
      transition: all 0.2s;
      color: desktop-variables.$main-white;
    }
  }

  &:disabled {
    transition: all 0.2s;
    opacity: 0.5;
    border: 1px solid desktop-variables.$main-gray-1;
    cursor: not-allowed;

    .title {
      transition: all 0.2s;
      color: desktop-variables.$main-gray-1;
    }
  }
}
