@use 'sass:map';

@import './variables.scss';

:root {
  --primary-bg: #{map.get($darkScheme, 'primary-bg')};
  --primary-text: #{map.get($darkScheme, 'primary-text')};
  --secondary-bg: #{map.get($darkScheme, 'secondary-bg')};
  --primary-input-border: #{map.get($darkScheme, 'primary-input-border')};
  --primary-form-bg: #{map.get($darkScheme, 'primary-form-bg')};
  --mb-login-bg: #{map.get($darkScheme, 'mb-login-bg')};
  --secondary-bg-2: #{map.get($darkScheme, 'secondary-bg-2')};
  --secondary-text: #{map.get($darkScheme, 'secondary-text')};
  --accent-red: #{map.get($darkScheme, 'accent-red')};
  --accent-green: #{map.get($darkScheme, 'accent-green')};
  --dark-1: #{map.get($darkScheme, 'dark-1')};
  --dark-2: #{map.get($darkScheme, 'dark-2')};
  --black: #{map.get($darkScheme, 'black')};
  --accent-blue: #{map.get($darkScheme, 'accent-blue')};
  --light-blue: #{map.get($darkScheme, 'light-blue')};
  --white: #{map.get($darkScheme, 'white')};
  --border-dark: #{map.get($darkScheme, 'border-dark')};
}

// :global(.light) {
//   --primary-bg: #{map.get($lightScheme, 'primary-bg')};
//   --primary-text: #{map.get($lightScheme, 'primary-text')};
//   --accent-blue: #{map.get($lightScheme, 'accent-blue')};
//   --primary-input-border: #{map.get($lightScheme, 'primary-input-border')};
//   --primary-form-bg: #{map.get($lightScheme, 'primary-form-bg')};
//   --mb-login-bg: #{map.get($lightScheme, 'mb-login-bg')};
//   --secondary-bg-2: #{map.get($lightScheme, 'secondary-bg-2')};
//   --secondary-text: #{map.get($lightScheme, 'secondary-text')};
//   --accent-red: #{map.get($lightScheme, 'accent-red')};
//   --accent-green: #{map.get($lightScheme, 'accent-green')};
//   --dark-1: #{map.get($lightScheme, 'dark-1')};
//   --dark-2: #{map.get($lightScheme, 'dark-2')};
//   --black: #{map.get($lightScheme, 'black')};
//   --white: #{map.get($lightScheme, 'white')};
//   --border-dark: #{map.get($lightScheme, 'border-dark')};
// }
