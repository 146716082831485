@use '/src/styles/desktop-variables.scss';

@import '/src/styles/core.scss';

.chartTab {
  all: unset;
  @include flex(center, center);
  padding: 6px 12px;
  border-radius: 4px;
  background-color: transparent;
  transition: all 0.2s;

  .title {
    color: desktop-variables.$main-gray-1;
    transition: all 0.2s;
  }

  &:hover {
    background: rgba(40, 40, 46, 0.5);
    transition: all 0.2s;
    cursor: pointer;

    .title {
      color: desktop-variables.$main-gray-1;
    }
  }

  &.selected {
    background-color: desktop-variables.$main-gray-3;
    transition: all 0.2s;

    .title {
      transition: all 0.2s;
      color: desktop-variables.$main-white;
    }
  }

  &:disabled {
    opacity: 0.5;
    transition: all 0.2s;

    .title {
      color: desktop-variables.$main-gray-1;
      transition: all 0.2s;
    }
  }
}
