@use '/src/styles/variables.scss';
@import '/src/styles/core.scss';

.wrapper {
  display: flex;
  flex-direction: column;
  padding: 4px 12px 12px 12px;
  row-gap: 12px;

  &.withoutBottomRow {
    row-gap: 0;
  }

  .row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding-left: 8px;
  }

  .topRow {
    padding-left: 0px;

    .icon {
      width: 40px;
      height: 40px;
    }
  }
}
