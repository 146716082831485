@use '/src/styles/desktop-variables.scss';

@import '/src/styles/core.scss';

.activityHistory {
  @include flex(flex-start, flex-start, column);
  min-width: 100%;
  max-width: 328px;
  row-gap: 16px;

  padding: 20px;
  border-radius: 6px;

  border: 1px solid desktop-variables.$main-gray-3;
  background-color: desktop-variables.$main-dark-gray-1;

  transition: all 0.2s;

  &:hover {
    transition: all 0.2s;
    background-color: #37373e;
  }

  .title {
    color: rgba($color: desktop-variables.$main-white, $alpha: 0.5);
  }

  .section {
    @include flex(flex-start, flex-start, column);
    row-gap: 12px;
    max-width: 100%;

    .heading {
      display: flex;
      align-items: center;
      color: rgba($color: desktop-variables.$main-white, $alpha: 0.7);
      max-height: 15px;
    }

    .subHeading {
      color: rgba($color: desktop-variables.$main-white, $alpha: 0.6);
      max-width: 100%;
      overflow-x: hidden;
      text-overflow: ellipsis;
    }
  }

  .descriptionWrapper {
    all: unset;
    @include flex(flex-start, flex-start, column);
    display: flex;
    width: 100%;

    .header {
      @include flex(center, space-between);
      width: 100%;
      cursor: pointer;

      .shortDescription {
        color: desktop-variables.$main-white;
      }

      .icon {
        @include flex(center, center);
        @include fill(desktop-variables.$main-white);
        height: 24px;
        width: 24px;
        transform: rotateX(0deg);
        transition: all 0.3s;
      }

      .chevronUp {
        transform: rotateX(180deg);
        transition: all 0.3s;
      }
    }

    .description {
      display: flex;
      width: 100%;
      color: rgba($color: desktop-variables.$main-white, $alpha: 0.7);
    }

    .hidden {
      width: 100%;
      max-height: 0;
      transition: all 0.3s;
      overflow: hidden;
      opacity: 0.15;
      margin-top: 0;
    }

    .expanded {
      transition: all 0.6s;
      max-height: 1000px;
      opacity: 1;
      margin-top: 1rem;
    }
  }
}
