@use '/src/styles/desktop-variables.scss';

@import '/src/styles/core.scss';

.wrapper {
  @include flex(flex-start, flex-start, column);
  row-gap: 0.25rem;
  width: 100%;

  .item {
    @include flex(center, flex-start);
    border-radius: 0.375rem;
    width: 100%;
    column-gap: 0.5rem;
    padding: 0.5rem;
    font-weight: 400;
    font-size: 1rem;
    line-height: 1.5rem;
    color: desktop-variables.$main-white;
    white-space: nowrap;

    &:hover {
      cursor: pointer;
      transition: all 0.2s;
      background-color: rgba(40, 40, 46, 0.5);
    }

    &:disabled {
      transition: all 0.2s;
      opacity: 0.5;
    }

    &:disabled:hover {
      cursor: not-allowed;
      background-color: inherit;
    }

    .icon {
      @include flex(center);
    }

    svg {
      transition: all 0.3s;
      width: 24px;
      height: 24px;

      * {
        fill: desktop-variables.$main-white;
      }
    }

    .up {
      transition: all 0.3s;
      transform: rotateX(180deg);
    }
  }

  .list {
    @include flex(flex-start, flex-start, column);
    justify-items: flex-start;
    row-gap: 0.25rem;
    width: 100%;
    max-height: 0px;
    margin-top: -0.25rem;
    transition: all 0.3s;
    // transform: scaleY(0) translateY(-100%);
    max-height: 0px;
    overflow: hidden;

    .item {
      transition: all 0.3s;
      opacity: 0.15;
      font-size: 0.875rem;
      line-height: 1.3125rem;

      svg {
        width: 16px;
        height: 16px;
      }
    }
  }

  .unfolded {
    max-height: 500px;
    margin-top: 0;
    transition: all 0.6s;
    // transform: scaleY(1) translateY(0);

    .item {
      transition: all 0.3s;
      opacity: 1;
    }
  }

  &.collapsed {
    > .item {
      * {
        column-gap: 0;
      }

      svg:not(:first-child),
      .label,
      .list {
        opacity: 0;
        padding: 0;
      }
    }
  }

  button,
  a {
    background-color: transparent;
    outline: unset;
    border: unset;
    text-decoration: none;
    color: desktop-variables.$main-white;

    &:hover {
      cursor: pointer;
    }

    &:focus {
      outline: unset;
    }

    &:visited {
      color: inherit;
    }

    &:disabled {
      transition: all 0.2s;
      opacity: 0.5;
    }

    &:disabled:hover {
      cursor: not-allowed;
    }
  }
}
