@import '/src/styles/core.scss';

$vnc-width: 50vw;
$vnc-height: 95vh;

.hidden {
  display: none;
}

.vnc {
  height: calc($vnc-height - 15rem) !important;
  width: calc($vnc-width - 4rem) !important;
}

.base {
  width: $vnc-width !important;
  max-width: none;
  max-height: none;
  min-height: calc($vnc-height - 15rem);

  div[class*='title'] {
    max-width: none !important;
  }

  .wrapper {
    @include flex(flex-start, flex-start, column);
    row-gap: 2rem;
    width: 100%;
    height: 600px;
    max-width: none !important;
  }
}
