.rdrStaticRanges {
  display: flex;
  flex-direction: column;
}

.rdrStaticRange {
  font-size: inherit;
}

.rdrInputRange {
  display: flex;
}
