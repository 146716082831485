@use '/src/styles/variables.scss';

@import '/src/styles/core.scss';

.wrapper {
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  row-gap: 16px;
  overflow: hidden;
  position: fixed;
  top: 0;
  width: 100vw;
  height: 100vh;
  background-color: variables.$main-dark-gray-2;
  z-index: variables.$z-notification-drawer;

  &.isOpened {
    animation: fromRightToLeft 0.25s cubic-bezier(0.5, 0, 1, 1);
  }
}

@keyframes fromRightToLeft {
  0% {
    right: -100%;
    opacity: 0;
  }
  100% {
    right: 0%;
    opacity: 1;
  }
}
