@use '/src/styles/variables.scss';

@import '/src/styles/core.scss';

.toggle {
  position: relative;
  display: inline-block;
  width: 51px;
  height: 31px;

  .label {
    @include flex();
    width: 100%;
    height: 100%;
    background-color: variables.$main-gray-1;
    border-radius: 1rem;
    transition: all 0.3s;

    &:hover {
      cursor: pointer;

      @include laptop-s() {
        transition: all 0.2s;
        background-color: #a9a9ab;
      }
    }
  }

  .checked {
    background-color: variables.$main-light-gray;
    transition: all 0.3s;

    @include laptop-s() {
      &:hover {
        background-color: #dee0e7;
      }
    }
  }

  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto 0;
    @include flex();
    height: 27px;
    width: 27px;
    left: 2px;
    background-color: rgba(255, 255, 255, 0.5);
    border-radius: 50%;
    pointer-events: none;
    opacity: 0.8;
    box-shadow:
      0px 3px 8px rgba(0, 0, 0, 0.15),
      0px 3px 1px rgba(0, 0, 0, 0.06);
    transition: all 0.3s ease-in-out;
  }

  .sliderChecked {
    -webkit-transform: translateX(20px);
    -ms-transform: translateX(20px);
    transform: translateX(20px);
    background-color: variables.$main-white;
    box-shadow:
      0px 3px 8px rgba(0, 0, 0, 0.15),
      0px 3px 1px rgba(0, 0, 0, 0.06);
    opacity: 1;
  }
}
