@use '/src/styles/variables.scss';

@import '/src/styles/core.scss';

.label {
  fill: variables.$main-white;
  cursor: pointer;
  color: rgba($color: #000000, $alpha: 1);
}

.chart {
  pointer-events: none;
}
