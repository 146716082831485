@use '/src/styles/variables.scss';
@import '/src/styles/mixins.scss';
@import '/src/styles/media.scss';

.wrapper {
  position: relative;
  @include flex(flex-start, flex-start, column);
  width: 100%;
  max-height: 100vh;
  min-height: 100vh;
  overflow: hidden;
  flex: 1;

  .background {
    position: absolute;
    top: 0;
    left: 0;
    @include flex();
    width: 100%;
    height: 100%;
    background: linear-gradient(180deg, rgba(15, 15, 17, 0) 0%, #0f0f11 57.7%);
    z-index: 1;
  }

  .bgImg {
    position: absolute;
    top: 0;
    right: 0;
  }

  .appHeader {
    @include flex(flex-start, flex-start);
    width: 100%;
    z-index: 2;
  }

  .content {
    @include flex(flex-start, flex-start, column);
    width: 100%;
    overflow: hidden;
    overflow-y: auto;
    z-index: 2;
    padding: 0 1.25rem 0 1.25rem;
  }
}

.desktopWrapper {
  @include laptop-s() {
    display: flex;

    > div {
      display: none !important;
    }
  }
}

.mobileWrapper {
  position: fixed;
  top: 0;
  left: 0;
  display: none;
  width: 100%;
  height: 100vh;

  @include laptop-s() {
    @include flex(center, center);
  }
}
