@use '/src/styles/variables.scss';

@import '/src/styles/core.scss';

.wrapper {
  display: flex;
  flex-direction: column;
  height: 100%;

  .content {
    display: flex;
    flex-direction: column;
    padding: 0 20px;

    .divider {
      border-bottom: 1px solid variables.$main-gray-3;
      width: 100%;
    }
  }
}

.header {
  display: flex;
  justify-content: space-between;
  padding: 24px 20px 16px;

  .left {
    display: flex;
    align-items: center;
    column-gap: 12px;
  }

  .icon {
    cursor: pointer;
  }
}

.controlButtons {
  display: flex;
  justify-content: space-between;
  padding: 16px 20px 40px;
  column-gap: 20px;
  margin-top: auto;

  & > button {
    width: 100%;
  }
}
