@import '../../styles/core.scss';

.tooltip {
  cursor: pointer;

  &Modal {
    display: none;
    position: absolute;
    right: 0;
    box-sizing: border-box;
    background-color: $primary-dark-2;
    padding: 10px 10px 10px 10px;
    border-radius: 4px;

    &:focus {
      transition: opacity $transition ease-out;
      opacity: 1;
    }

    &Top {
      top: -25px;
    }

    &Bottom {
      bottom: -25px;
    }
  }

  &Child {
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    right: 0;
  }

  &:hover &Modal,
  &:active &Modal {
    display: block;
  }
}
