@import '../../styles/core.scss';

.overlay {
  @include flex(center, center);

  height: 100%;
  width: 100%;
  position: absolute;
  background-color: var(--light-blue);
  pointer-events: none;

  &Bar {
    @include flex(flex-start, center, column);

    background-color: #edeef2;
    width: 40%;
    height: 8px;
    border-radius: 4px;

    &Filler {
      background-color: var(--accent-blue);
      animation: filler 0.5s infinite;
      height: 8px;
      border-radius: 4px;
    }

    &Blank {
      visibility: hidden;
    }
  }
}

@keyframes filler {
  0% {
    width: 0%;
  }
  100% {
    width: 100%;
  }
}
