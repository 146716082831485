@use '/src/styles/desktop-variables.scss';

@import '/src/styles/core.scss';

.yieldChart {
  @include flex(flex-start, flex-start, column);
  margin-bottom: 76px;

  position: relative;

  width: 100%;
  height: 268px;

  .tooltip {
    position: relative;
    padding: 2px 3px 1px 5px;
    width: fit-content;
    background: desktop-variables.$secondary-green;
    border-radius: 4px;
  }

  .label {
    position: absolute;
    right: 0;
    width: 50px;
    height: 10px;
    background-color: desktop-variables.$secondary-green;
  }

  .customLabel {
    text {
      font-size: 12px;
      line-height: 18px;
      font-family: desktop-variables.$road-ua;
      text-align: center;
    }
  }

  .legend {
    margin-top: 12px;
  }
}
