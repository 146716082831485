@use '/src/styles/variables.scss';

@import '/src/styles/core.scss';

.wrapper {
  @include flex(center, center);
  max-width: fit-content;
  column-gap: 12px;
  user-select: none;

  .checkWrapper {
    @include flex(center, center);
    width: 24px;
    height: 24px;

    border: 2px solid variables.$main-gray-3;
    border-radius: 4px;
    transition: all 0.2s;

    @include laptop-s() {
      &:hover {
        transition: all 0.2s;
        border-color: #5d5d6c;
      }

      &:focus {
        transition: all 0.2s;
        border-color: #41414b;
      }
    }

    &:hover {
      cursor: pointer;
    }

    .checkIcon {
      @include flex(center, center);
      transition: all 0.2s;
    }

    .invisibleCheck {
      opacity: 0;
      transition: all 0.2s;
    }
  }

  input:disabled ~ .label,
  input:disabled ~ .checkWrapper {
    @include laptop-s() {
      transition: all 0.2s;
      opacity: 0.7;

      &:hover {
        transition: all 0.2s;
        cursor: not-allowed;
      }
    }

    opacity: 0.7;

    &:hover {
      cursor: not-allowed;
    }
  }

  .checked {
    transition: all 0.2s;
    border: 2px solid rgba(variables.$main-white, 0.5);

    @include laptop-s() {
      &:hover {
        transition: all 0.2s;
        border-color: #a9a9ab;
      }

      &:focus {
        transition: all 0.2s;
        border-color: #808082;
      }
    }
  }

  .label {
    font-size: 0.875rem; // 14px
    font-weight: 400;
    line-height: 150%;
  }
}
