@use '/src/styles/desktop-variables.scss';

@import '/src/styles/core.scss';

.summariesChart {
  @include flex(flex-start, flex-start, column);
  row-gap: 1.5rem;
  width: 100%;
  height: 254px;

  .tooltip {
    @include flex(flex-start, center, column);
    row-gap: 0.5rem;
    padding: 0.5rem 0.75rem;
    border-radius: 6px;
    background-color: desktop-variables.$main-gray-3;

    .tooltipRow {
      position: relative;
      @include flex(center, flex-start);

      &:not(:last-child) {
        padding-left: 14px;

        &::before {
          content: '';
          position: absolute;
          left: 0;
          top: 3px;
          width: 10px;
          height: 10px;
          border-radius: 2px;
        }
      }

      &.positive {
        &:first-child::before {
          background-color: desktop-variables.$secondary-green;
        }
      }

      &.negative {
        &:first-child::before {
          background-color: desktop-variables.$secondary-red;
        }
      }

      &:first-child::before {
        background-color: desktop-variables.$main-white;
      }

      &:nth-child(2)::before {
        background-color: desktop-variables.$main-white;
      }
    }
  }
}
