@use '/src/styles/variables.scss';

@import '/src/styles/core.scss';

.pillTab {
  all: unset;
  @include flex(center, center);
  cursor: pointer;
  transition: all 0.3s;

  .title {
    color: variables.$main-white;
    text-transform: unset;
    transition: all 0.3s;
  }

  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
    transition: all 0.3s;
  }

  &.selected {
    transition: all 0.3s;
  }

  /**
  * SIZES.
  */

  &.small {
    border-radius: 4px;
    padding: 0.375rem;

    .title {
      color: variables.$main-gray-1;
    }

    &.selected {
      background: variables.$main-dark-gray-1;

      .title {
        color: variables.$main-white;
      }
    }
  }

  &.medium {
    padding: 0.25rem 0.5rem;
    background: variables.$main-black-1;
    border: 1px solid variables.$main-gray-5;

    .title {
      opacity: 0.7;
    }

    &.selected {
      background: variables.$main-gray-5;

      .title {
        opacity: 1;
      }
    }
  }

  &.large {
    @include flex();
    padding: 0.75rem 0.5rem;

    &.selected {
      background: variables.$main-dark-gray-2;
    }
  }
}
