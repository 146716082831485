@use '/src/styles/desktop-variables.scss';
@import '/src/styles/core.scss';

.wrapper {
  display: grid;
  width: 100%;
  height: 100%;
  max-height: 100%;
  overflow: hidden;
  column-gap: 1.5rem;
}
