@use '/src/styles/desktop-variables.scss';

@import '/src/styles/core.scss';

.wrapper {
  display: grid;
  width: 100%;
  min-width: fit-content;
  max-width: 100%;
  grid-auto-columns: auto;
  grid-template-rows: auto;
  grid-auto-flow: column;
  justify-content: flex-start;

  .link,
  > * {
    display: flex;
    justify-content: center;
    width: fit-content;
    min-width: fit-content;
    text-decoration: unset;

    &:visited {
      color: inherit;
    }
  }
}

.settingsMenuWrapper {
  border-bottom: 2px solid desktop-variables.$main-dark-gray-1;
}
