@use '/src/styles/desktop-variables.scss';
@import '/src/styles/core.scss';

.customLegend {
  grid-gap: 16px;
  display: grid;
  grid-template-columns: repeat(3, auto);
  justify-content: start;
}

.green,
.mint,
.dashed {
  display: block;
  width: 10px;
  height: 10px;
  margin-right: 4px;
  border-radius: 2px;
}
.green {
  background: desktop-variables.$secondary-green;
}

.mint {
  background: desktop-variables.$secondary-turquoise;
}

.dashed {
  border: 1px dashed desktop-variables.$main-white;
}

.legend {
  display: flex;
  align-items: center;
}
