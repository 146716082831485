@use './variables.scss';
@import './normalize.scss';

:global #reportsPage {
  color: inherit;
  font-family: variables.$montserrat;
  font-size: variables.$normal-size;
  font-weight: variables.$normal-weight;
  background-color: variables.$primary-dark;
  scrollbar-width: none;
  overflow: auto;
  // Firefox scrollbar invisible
  scrollbar-color: transparent transparent !important;
  scrollbar-width: thin !important;
  background-color: #28282e;

  [pointer-events='bounding-box'] {
    display: none;
  }

  svg g[aria-hidden='true'] {
    display: none;
  }
}
