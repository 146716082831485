@use '/src/styles/variables.scss';

@import '/src/styles/core.scss';

.wrapper {
  @include flex(flex-start, flex-start, column);
  width: 100%;
  row-gap: 0.75rem;

  .description {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
  }

  .progressBar {
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 10px;
    border-radius: 2px;
    position: relative;

    // Background bar
    &.dark {
      background-color: variables.$main-background;
    }

    &.light {
      background-color: variables.$main-dark-gray-2;
    }

    // Active bar
    .dark,
    .light {
      background-color: variables.$secondary-yellow;
    }

    .grow {
      padding-top: 2px;
      animation-duration: 1s;
      animation-name: slidein;
      border-radius: 2px;
      height: 100%;
    }

    .percentage {
      @include flex(center, center);
      height: 100%;
      position: absolute;
      right: 0;
      left: 0;
      margin: auto;
      opacity: 0.5;
      animation-duration: 1.2s;
      animation-name: appear;
    }

    .percentageDark {
      color: variables.$main-background;
      opacity: 1;
    }

    .disabled {
      display: none;
    }
  }

  @keyframes slidein {
    from {
      width: 0;
    }
  }

  @keyframes appear {
    from {
      opacity: 0;
    }

    50% {
      opacity: 0;
    }
  }
}
