@use '/src/styles/desktop-variables.scss';

@import '/src/styles/core.scss';

.accordionHeader {
  @include flex(center, space-between);
  column-gap: 1rem;
  width: 100%;
  padding-bottom: 0;
  border-bottom: 0px solid transparent;
  transition: all 0.3s;
  cursor: pointer;

  .on {
    transform: rotate(180deg);
    transition: 0.3s;
  }

  .off {
    transform: rotate(0deg);
    transition: 0.3s;
  }
}

.expandedHeader {
  border-bottom: 1px solid desktop-variables.$main-gray-3;
  transition: all 0.3s;
  padding-bottom: 1.25rem;
}

.expandedHeaderWithoutLine {
  transition: all 0.3s;
}
