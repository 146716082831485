@use '/src/styles/desktop-variables.scss';

@import '/src/styles/core.scss';

.rdrDay {
  box-sizing: inherit;
  width: calc(100% / 7);
  position: relative;
  font: inherit;
  cursor: pointer;
  font-size: 14px;
  height: 48px;
}

.rdrDayNumber {
  display: block;
  position: relative;
  span {
    color: desktop-variables.$main-white;
  }
}

.rdrDayDisabled {
  cursor: not-allowed;
}

@supports (-ms-ime-align: auto) {
  .rdrDay {
    flex-basis: 14.285% !important;
    height: 48px;
  }
}

.rdrSelected,
.rdrInRange,
.rdrStartEdge,
.rdrEndEdge {
  pointer-events: none;
}

.rdrDayStartPreview,
.rdrDayInPreview,
.rdrDayEndPreview {
  pointer-events: none;
}
