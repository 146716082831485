@import '/src/styles/core.scss';

.wrapper {
  @include flex(flex-start, flex-start, column);
  row-gap: 2.5rem;
  width: 100%;

  .subtitle {
    opacity: 0.8;
  }

  .controls {
    @include flex(center, space-between);
    column-gap: 1.25rem;
    width: 100%;

    button {
      width: calc(50% - 0.625rem);
    }
  }
}
