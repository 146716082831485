@import '/src/styles/core.scss';

.wrapper {
  @include flex(flex-start, flex-start, column);
  row-gap: 2.5rem;
  width: 100%;

  .text {
    @include flex(flex-start, flex-start, column);
    row-gap: 1rem;
    width: 100%;

    .checkboxBlock {
      @include flex(center, flex-start);
      column-gap: 0.75rem;
      width: 100%;
    }
  }

  button {
    width: 100%;
  }
}
