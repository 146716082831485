@use '/src/styles/variables.scss';

@import '/src/styles/core.scss';

.overlay {
  width: 100%;
  min-height: 100vh;
  z-index: variables.$z-overlay;
  position: fixed;
  top: 0;
  left: 0;
  overflow: hidden;
  background: rgba(32, 32, 37, 0.2);
}

.full {
  width: 100%;
}

.enter {
  opacity: 0;
}
.enter-active {
  opacity: 1;
  transition: opacity 0.3s;
}
.exit {
  opacity: 1;
}
.exit-active {
  opacity: 0;
  transition: opacity 0.3s;
}
