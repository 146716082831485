@import '../../../styles/core.scss';

.cell {
  padding: 20px 16px;

  &Align {
    text-align: left;

    &Center {
      text-align: center;
    }

    &Right {
      text-align: right;
    }
  }

  &Adornment {
    @include flex(center, space-between, row);
  }
}
