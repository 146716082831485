@use '/src/styles/desktop-variables.scss';

@import '/src/styles/core.scss';

.wrapper {
  @include flex(flex-start, flex-start, column);
  row-gap: 1.25rem;
  background-color: desktop-variables.$main-dark-gray-2;
  padding: 1.5rem 1rem 1rem 1rem;

  &.collapsed {
    padding: 1.5rem 0.75rem 1rem 0.75rem;
  }

  .menu {
    @include flex(flex-start, flex-start, column);
    row-gap: 2rem;
    width: 100%;
    flex-grow: 1;
    overflow: hidden;
    overflow-y: auto;

    .section {
      position: relative;
      @include flex(flex-start, flex-start, column);
      row-gap: 0.75rem;
      width: 100%;

      &:not(:last-child)::after {
        position: absolute;
        bottom: -16px;
        left: 0;
        display: block;
        content: '';
        width: 100%;
        height: 0.0625rem;
        background-color: rgba(desktop-variables.$main-gray-3, 0.5);
      }
    }
  }

  .collapseBtn {
    @include flex(center, flex-start);
    column-gap: 0.5rem;
    height: 2.25rem;
    width: 100%;
    padding-left: 0.5rem;
    cursor: pointer;
    background-color: inherit;
    color: desktop-variables.$main-white;
    border: none;
    outline: none;

    .btnText {
      transition: opacity 0.2s;
      white-space: nowrap;
      overflow-x: hidden;

      &--collapsed {
        width: 0;
      }
    }
  }
}
