@use '/src/styles/variables.scss';

@import '/src/styles/core.scss';

.wrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  row-gap: 0.25rem;
  width: 100%;
  padding-top: 1.8125rem;
  opacity: 1;
  transition: all 0.2s;

  .label {
    position: absolute;
    top: 29px;
    left: 0;
    @include flex(center, flex-start);
    width: fit-content;
    height: 1.5rem;
    font-size: 0.875rem;
    line-height: 1.3125rem;
    font-weight: 400;
    color: variables.$main-gray-1;
    transform: translate(0, 0);
    transition: all 0.3s;
    pointer-events: none;
    white-space: nowrap;
  }

  .marginedLabel {
    transform: translate(32px, 0);
  }

  .movedLabel {
    transform: translate(0, -29px);
    transition: all 0.3s;
  }

  .inputContainer {
    position: relative;
    display: flex;
    column-gap: 0.5rem;
    width: 100%;
    border-bottom: 1px solid variables.$main-gray-1;
    transition: all 0.3s;
    padding-bottom: 0.25rem;

    .inputElement {
      width: 100%;
      background-color: transparent !important;
      border: unset;
      outline: unset;

      font-size: 1rem;
      line-height: 1.5rem;
      font-weight: 400;
      color: variables.$main-white !important;

      &:-webkit-autofill,
      &:-webkit-autofill:hover,
      &:-webkit-autofill:focus,
      &:-webkit-autofill:active {
        color: #c4c4c4;
        -webkit-text-fill-color: variables.$main-white !important;
        transition: background-color 9999s ease-in-out 0s;
      }
    }

    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    input[type='number'] {
      -moz-appearance: textfield;
      appearance: textfield;
    }

    .icon {
      position: absolute;
      top: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 24px;
      height: 24px;
      transition: all 0.3s;

      &:hover {
        cursor: default;
      }
    }

    .formattedSVGIcon {
      color: variables.$main-gray-1;

      svg {
        * {
          fill: variables.$main-gray-1;
        }
      }
    }

    .tailingIcon {
      right: 0;
    }

    .leadingIcon {
      left: 0;
    }

    &:focus-within {
      border-color: variables.$main-white;
      transition: all 0.3s;
    }
  }

  .inputFooter {
    display: flex;
    position: relative;

    .leftDesciptionList {
      @include flex(flex-start, center, column);
      row-gap: 0.25rem;
      padding-left: 0.75rem;

      .leftDescription {
        position: relative;

        &::before {
          content: '';
          width: 0.25rem;
          height: 0.25rem;
          border-radius: 50%;
          background-color: variables.$main-gray-2;
          position: absolute;
          top: calc(50% - 0.2rem);
          left: -0.75rem;
        }
      }
    }

    .leftDescription {
      color: variables.$main-gray-2;
    }

    .rightDescription {
      color: variables.$main-gray-1;
      text-transform: uppercase;
      position: absolute;
      right: 0;

      &.pointer {
        cursor: pointer;
      }
    }
  }

  .movedLabel ~ .currencyContainer:before {
    content: '$ ';
    display: block;
    height: 100%;
    font-size: 1rem;
    line-height: 1.5rem;
    font-weight: 400;
    color: variables.$main-white !important;
    margin-right: -0.5rem;
    transition: all 0.2s;
  }

  .errored {
    color: variables.$secondary-red-1;
    border-color: variables.$secondary-red-1;
  }

  .withLeadingIcon {
    padding-left: 1.75rem;
  }

  .withTailingIcon {
    padding-right: 2rem;
  }

  .errorList {
    @include flex(flex-start, center, column);
    row-gap: 0.25rem;
    padding-left: 0.75rem;

    .error {
      position: relative;

      &::before {
        content: '';
        width: 0.25rem;
        height: 0.25rem;
        border-radius: 50%;
        background-color: variables.$secondary-red-1;
        position: absolute;
        top: calc(50% - 0.2rem);
        left: -0.75rem;
      }
    }
  }

  .error {
    font-size: 0.75rem;
    line-height: 1.125rem;
    font-weight: 400;
    color: variables.$secondary-red-1;
  }
}

.disabled {
  opacity: 0.5;
  transition: all 0.2s;

  .inputContainer {
    &:hover {
      cursor: not-allowed;
    }
    .inputElement {
      &:hover {
        cursor: not-allowed;
      }
    }
  }
}
