@use '/src/styles/desktop-variables.scss';

@import '/src/styles/core.scss';

.accordion {
  @include flex(flex-start, flex-start, column);
  @include appear();
  padding-bottom: 1.25rem;
  width: 100%;
  padding: 1.5rem;
  background: desktop-variables.$main-dark-gray-1;
  border-radius: 6px;
  transition: all 0.3s ease;

  &:hover {
    transition: all 0.3s;
    background-color: #2c2c32;
  }

  &.disabled {
    opacity: 50%;
  }

  .hidden {
    width: 100%;
    max-height: 0;
    transition: all 0.3s;
    overflow: hidden;
    opacity: 0.15;
  }

  .expanded {
    transition: all 1.2s;
    max-height: min-content;
    opacity: 1;
  }
}
