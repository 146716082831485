@use '/src/styles/desktop-variables.scss';

@import '/src/styles/core.scss';

.langTypo {
  padding-left: 8px;
  width: 60px;
}

.wrapper {
  @include flex(center, space-between);
  grid-column: 1 / 3;

  .innerWrapper {
    @include flex(center, flex-end);
    column-gap: 40px;
    flex-grow: 1;
    background-color: desktop-variables.$main-dark-gray-2;
    padding: 0.5rem 1.5rem;
  }

  .logo {
    @include flex(center, flex-start);
    background: #202025;
    height: 3.5rem;
    padding: 1rem 0 1rem 1.5rem;
    column-gap: 15px;
    color: desktop-variables.$main-white;

    img {
      max-width: 100%;
      max-height: 100%;
      object-fit: contain;
    }

    .companyName {
      white-space: nowrap;
    }
  }

  .currentRole {
    @include flex(center);
    column-gap: 0.5rem;
    width: fit-content;
  }

  .connections {
    @include flex(center);
    column-gap: 1.5rem;
    width: fit-content;
  }

  .controls {
    @include flex(center);
    column-gap: 0.75rem;
    width: fit-content;

    .languages {
      > div {
        column-gap: 0.25rem;
      }

      .chevron {
        transition: transform 0.3s ease;
      }

      .rotate {
        transform: rotateX(180deg);
      }
    }

    .control {
      padding: 0.5rem;
      border: unset;

      svg:hover {
        cursor: pointer;
      }
    }
  }
}
